import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from 'app/_services/rest.service';

@Component({
  selector: 'app-edit-veiculo-prevenda',
  templateUrl: './edit-veiculo-prevenda.component.html',
  styleUrls: ['./edit-veiculo-prevenda.component.less']
})
export class EditVeiculoPrevendaComponent implements OnInit {
  veiculoPreVendaForm = this.formBuilder.group({
    idVeiculoPreVenda: [null],
    produtoId: [null],
    descricao: [{ value: '', disabled: true }],
    prazo: [{ value: null, disabled: true }],
    cor: [{ value: '', disabled: true }],
    dataCriacao: [{ value: '', disabled: true }],
    dataAtualizacao: [{ value: '', disabled: true }],
    ativa: [null, Validators.required],
    imagemBase64: ['']
  });

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly restService: RestService,
    private route: ActivatedRoute,
    private readonly location: Location,
    private readonly datePipe: DatePipe,
    private readonly _snackBar: MatSnackBar,
    private readonly router: Router
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.get('id') !== null) {
          const id = params.get('id') ? params.get('id') : null;
          
          if (!id) this.location.back();

          this.getMotoPreVendaByProdutoId(parseInt(id));
      }
    });
  }

  getMotoPreVendaByProdutoId(id: number) {
    this.restService.getMotoPreVendaByProdutoId(id)
    .subscribe(res => {
      res.dataAtualizacao = this.datePipe.transform(res.dataAtualizacao, 'yyyy-MM-dd');
      res.dataCriacao = this.datePipe.transform(res.dataCriacao, 'yyyy-MM-dd');
      this.veiculoPreVendaForm.patchValue(res);
    });
  }

  onSubmit() {
    if (this.veiculoPreVendaForm.invalid) {
      return;
    }

    this.restService.putVeiculoPreVenda(
      this.veiculoPreVendaForm.get('produtoId').value,
      this.veiculoPreVendaForm.get('ativa').value
    ).subscribe(message => {
      this._snackBar.open(message, "fechar", {
        duration: 4000,
        panelClass: ['success-snackbar']
      });
      this.back();
    }, error => {
      alert("Não foi possível atualizar o veículo: " + error.error);
    });
  }

  back() {
    this.location.back();
  }

}
