import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card',
  template: `
    <div class="register-box">
      <div *ngIf="tituloCard">
        <h5>{{tituloCard}}</h5>
        <hr>
      </div>
      <ng-content>
      </ng-content>
    </div>
  `,
  styleUrls: ['./card.component.less']
})
export class CardComponent implements OnInit {
  tituloCard: string = '';

  constructor() { }

  ngOnInit() {
  }

  @Input() set titulo(titulo: string) {
    this.tituloCard = titulo.trim();
  }

}
