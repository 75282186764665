import { ClasseProduto } from 'app/_models/classeProduto';
import { Payment } from './../_models/payment';

import { map, take, tap } from 'rxjs/operators';
import { Moto } from './../_models/moto';

import { Consulta } from './../_models/consulta';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { BehaviorSubject, Observable } from 'rxjs';

import { User } from '../_models/user';
import { Product } from '../_models/product';
import { Agendamento } from '../_models/agendamento';
import { Indicacao } from '../_models/indicacao';

import { UtilsService } from '../_services/utils.service';
import { environment } from '../../environments/environment';
import { Parcelamento } from 'app/_models/parcelamento';
import { TarifaCartao } from 'app/_models/tarifaCartao';
import { PreVenda } from 'app/_models/prevendas';
import { SaldoGeral } from 'app/_models/saldoGeral';
import { Menu } from 'app/_models/menu';
import { TipoPessoa } from 'app/_models/tipoPessoa';
import { Perfis } from 'app/_enums/perfis.enum';
import { ProdutoNovo } from 'app/_models/produtoNovo';
import { CadastroCanal, Canal } from 'app/_models/canal';
import { PoliticaProduto } from 'app/_models/politicaProdutos';
import { Banco, CadastroBanco } from 'app/_models/banco';
import { CadastroClasseProduto } from 'app/_models/classeProduto';
import { Convenio } from 'app/_models/classeProduto';
import { CadastroConvenio } from 'app/_models/classeProduto';
import { Tipo } from 'app/_models/tipo';
import { WebCertificadoTipo } from 'app/_models/webCertificadoTipo';
import { WebCertificadoTipoPessoa } from 'app/_models/webCertificadoTipoPessoa';
import { ModalService } from './modal.service';
import { Response } from 'app/_models/response';
import { PessoaResponse } from 'app/_models/pessoa-response';
import { PessoaRequest } from 'app/_models/pessoa-request';
import { VwUsuario } from 'app/_models/vw-usuario';
import { UsuarioRequest } from 'app/_models/usuario-request';
import { TransferenciaMultipla } from 'app/_models/transferenciaMultipla';
import { VeiculoPreVenda } from 'app/_models/VeiculosPreVenda';
import { OperacaoFinanceiraResume } from 'app/_models/operacaoFinanceiraResume';
import { IPeopleOperation } from 'app/_models/movimentacoes';

@Injectable()
export class RestService {
  readonly SISTEMA = 'VALORIZA_WEB'

  private controleDeAcesseObservable = new BehaviorSubject<Menu>(null);
  controleDeAcesso$ = this.controleDeAcesseObservable.asObservable();

  baseApiPath: string = environment.url_api;
  baseApiNotaPath: string = environment.url_api_nota;

  private _headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private _utilsService: UtilsService,
    private modalService: ModalService

  ) {
    this.setToken();
  }

  setToken() {
    let currentUser: User;
    let currentUserJson: String = JSON.parse(sessionStorage.getItem('currentUser'));

    if (currentUserJson != null) {
      currentUser = new User(currentUserJson);

      this._headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + currentUser.get('token')
      })
    }
  }

  getListaCertificado(pessoa, tipo): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseApiPath}/api/produto/certificado/${pessoa}/${tipo}`, { headers: this._headers });
  }

  gerarBoleto(codigoCRM, idCliente, total) {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/boleto/" + codigoCRM + "/" + idCliente + "/" + total, { headers: this._headers });
  }

  segundaViaBoleto(codigoCRM) {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/boleto/segundavia/" + codigoCRM, { headers: this._headers });
  }

  gerarRemessa() {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/boleto/remessa", { headers: this._headers });
  }

  checkProductActive(idProduct): Observable<boolean> {
    this.setToken();
    return this.http.get<boolean>(this.baseApiPath + '/api/produto/ativo/' + idProduct, { headers: this._headers });
  }

  getDispositivos(): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/produto/dispositivos', { headers: this._headers });
  }

  getCartProducts(product: String): Observable<Product[]> {
    this.setToken();
    return this.http.get<Product[]>(this.baseApiPath + '/api/produto/' + product, { headers: this._headers });
  }

  getTodosTipoPessoa(): Observable<TipoPessoa[]> {
    this.setToken();
    return this.http.get<TipoPessoa[]>(this.baseApiPath + '/api/tipo-pessoa', { headers: this._headers }).pipe(
      map(res => res['data']));
  }

  checkIfHasIndicador(cpfCnpj: String): Observable<Product[]> {
    this.setToken();
    return this.http.get<Product[]>(this.baseApiPath + "/api/indicador/" + cpfCnpj, { headers: this._headers });
  }

  getMaxDiscount(flagMoto: boolean): Observable<number> {
    this.setToken();
    return this.http.get<number>(this.baseApiPath + '/api/pessoa/desconto/' + flagMoto, { headers: this._headers });
  }

  getClient(clienteCode: string): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/pessoa/' + clienteCode, { headers: this._headers });
  }

  buscarPessoaSelect(text: string): Observable<any> {
    return this.http.post(this.baseApiPath + '/api/pessoa/select', text, { headers: this._headers });
  }

  getPessoaGeneric(id): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/pessoa/generic/' + id, { headers: this._headers });
  }

  getMe(): Observable<Response<PessoaResponse>> {
    this.setToken();
    return this.http.get<Response<PessoaResponse>>(this.baseApiPath + '/api/pessoa/me/', { headers: this._headers });
  }
  
  editarMe(pessoaRequest: PessoaRequest): Observable<Response<PessoaResponse>> {
    console.log(pessoaRequest);
    return this.http.put<Response<PessoaResponse>>(this.baseApiPath + "/api/pessoa/me", pessoaRequest, { headers: this._headers });
  }

  findAllVwUsuario(): Observable<Response<VwUsuario[]>> {
    this.setToken();
    return this.http.get<Response<VwUsuario[]>>(`${this.baseApiPath}/api/usuario`, { headers: this._headers });
  }

  findByIdVwUsuario(idUsuario: number): Observable<Response<VwUsuario>> {
    this.setToken();
    return this.http.get<Response<VwUsuario>>(`${this.baseApiPath}/api/usuario/${idUsuario}`, { headers: this._headers });
  }

  saveVwUsuarioById(idUsuario: number, usuarioRequest: UsuarioRequest): Observable<Response<VwUsuario>> {
    this.setToken();
    return this.http.put<Response<VwUsuario>>(`${this.baseApiPath}/api/usuario/${idUsuario}`, usuarioRequest, { headers: this._headers });
  }

  getPessoaIndicacao(id): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/pessoa/id/' + id, { headers: this._headers });
  }

  getPessoaCliente(id): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/pessoa/cliente/id/' + id, { headers: this._headers });
  }

  getPessoaPdv(id): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/pessoa/pdv/id/' + id, { headers: this._headers });
  }

  getPessoaIndicador(id): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/pessoa/indicador/id/' + id, { headers: this._headers });
  }

  getPessoaGestor(id): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/pessoa/gestor/id/' + id, { headers: this._headers });
  }

  getPessoaVendedor(id): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/pessoa/vendedor/id/' + id, { headers: this._headers });
  }

  getPessoaFornecedor(id): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/pessoa/fornecedor/id/' + id, { headers: this._headers });
  }

  getPessoaConta(id): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/pessoa/conta/id/' + id, { headers: this._headers });
  }

  getTipoPessoa(id): Observable<TipoPessoa> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/tipo-pessoa/' + id, { headers: this._headers }).pipe(
      map(res => res['data']));
  }

  getPessoas(texto, tipos): Observable<any> {
    this.setToken();

    const params = new HttpParams().set('texto', texto).set('tipos', tipos);

    return this.http.get(this.baseApiPath + '/api/pessoa/pessoas', { headers: this._headers, params: params });
  }

  getPontosDeVendaPessoa(texto): Observable<any> {
    this.setToken();

    const params = new HttpParams().set('texto', texto);

    return this.http.get(this.baseApiPath + '/api/pessoa/pontos-de-venda', { headers: this._headers, params: params });
  }

  getPessoasIndicacao(): Observable<any[]> {
    this.setToken();
    return this.http.get<any[]>(this.baseApiPath + '/api/pessoa', { headers: this._headers });
  }

  updateClient(cliente): Observable<any> {
    let params = JSON.stringify({
      id: cliente.id,
      cpfCnpj: cliente.cpfCnpj,
      nome: cliente.nome,
      endereco: cliente.endereco,
      nro: cliente.nro,
      bairro: cliente.bairro,
      cidade: cliente.cidade,
      cMun: cliente.cMun,
      cep: cliente.cep,
      fone: cliente.fone,
      celular: cliente.celular,
      email: cliente.email,
      uf: cliente.uf,
      dataNascimento: cliente.dataNascimento,
      tipoPessoa: cliente.tipoPessoa,
      sexo: cliente.sexo,
      pessoaReferencia: cliente.pessoaReferencia,
      telefoneReferencia: cliente.telefoneReferencia,
      idIndicador: cliente.idIndicador,
      prospeccaoIndicador: cliente.prospeccaoIndicador,
      cpfPessoaReferencia: cliente.cpfPessoaReferencia,
      tipo: cliente.tipo,
      inscricaoEstadual: cliente.inscricaoEstadual,
    });

    this.setToken();
    return this.http.put(this.baseApiPath + "/api/pessoa", params, { headers: this._headers });
  }

  updateCliente(cliente): Observable<any> {
    let params = JSON.stringify({
      id: cliente.id,
      idOperacoes: cliente.idOperacoes,
      cpfCnpj: cliente.cpfCnpj,
      nome: cliente.nome,
      endereco: cliente.endereco,
      nro: cliente.nro,
      bairro: cliente.bairro,
      cidade: cliente.cidade,
      cMun: cliente.cMun,
      cep: cliente.cep,
      fone: cliente.fone,
      celular: cliente.celular,
      email: cliente.email,
      uf: cliente.uf,
      dataNascimento: cliente.dataNascimento,
      tipoPessoa: cliente.tipoPessoa,
      sexo: cliente.sexo,
      pessoaReferencia: cliente.pessoaReferencia,
      telefoneReferencia: cliente.telefoneReferencia,
      cpfPessoaReferencia: cliente.cpfPessoaReferencia,
      tipo: cliente.tipo,
      inscricaoEstadual: cliente.inscricaoEstadual
    });

    this.setToken();
    return this.http.put(this.baseApiPath + "/api/pessoa/cliente", params, { headers: this._headers });
  }

  updatePdv(pdv): Observable<any> {
    let params = JSON.stringify({
      id: pdv.id,
      idOperacoes: pdv.idOperacoes,
      acessaNet: pdv.acessaNet,
      cpfCnpj: pdv.cpfCnpj,
      nome: pdv.nome,
      endereco: pdv.endereco,
      nro: pdv.nro,
      bairro: pdv.bairro,
      cidade: pdv.cidade,
      cMun: pdv.cMun,
      cep: pdv.cep,
      fone: pdv.fone,
      celular: pdv.celular,
      email: pdv.email,
      uf: pdv.uf,
      tipoPessoa: pdv.tipoPessoa,
      tipo: pdv.tipo,
      senhaNet: pdv.senhaNet,
      inscricaoEstadual: pdv.inscricaoEstadual
    });

    this.setToken();
    return this.http.put(this.baseApiPath + "/api/pessoa/pdv", params, { headers: this._headers });
  }

  updateIndicador(indicador): Observable<any> {
    let params = JSON.stringify({
      id: indicador.id,
      idOperacoes: indicador.idOperacoes,
      cpfCnpj: indicador.cpfCnpj,
      nome: indicador.nome,
      endereco: indicador.endereco,
      nro: indicador.nro,
      bairro: indicador.bairro,
      cidade: indicador.cidade,
      cMun: indicador.cMun,
      cep: indicador.cep,
      fone: indicador.fone,
      celular: indicador.celular,
      email: indicador.email,
      uf: indicador.uf,
      dataNascimento: indicador.dataNascimento,
      tipoPessoa: indicador.tipoPessoa,
      tipo: indicador.tipo,
      comissao: indicador.comissao
    });

    this.setToken();
    return this.http.put(this.baseApiPath + "/api/pessoa/indicador", params, { headers: this._headers });
  }

  updateClientBasic(cliente): Observable<any> {
    let params = JSON.stringify({
      id: cliente.id,
      cpfCnpj: this._utilsService.parseStringVazia(cliente.cpfCnpj),
      nome: this._utilsService.parseStringVazia(cliente.nome),
      endereco: this._utilsService.parseStringVazia(cliente.endereco),
      bairro: this._utilsService.parseStringVazia(cliente.bairro),
      cidade: this._utilsService.parseStringVazia(cliente.cidade),
      cep: this._utilsService.parseStringVazia(cliente.cep),
      cMun: this._utilsService.parseStringVazia(cliente.cMun),
      fone: this._utilsService.parseStringVazia(cliente.fone),
      celular: this._utilsService.parseStringVazia(cliente.celular),
      email: this._utilsService.parseStringVazia(cliente.email),
      uf: this._utilsService.parseStringVazia(cliente.uf),
      dataNascimento: this._utilsService.parseStringVazia(cliente.dataNascimento)
    });

    this.setToken();
    return this.http.put(this.baseApiPath + "/api/pessoa/basic", params, { headers: this._headers });
  }

  updateGestor(gestor): Observable<any> {
    const params = {
      ...gestor
    };

    this.setToken();
    return this.http.put(this.baseApiPath + "/api/pessoa/gestor", JSON.stringify(params), { headers: this._headers });
  }

  updateVendedor(vendedor): Observable<any> {
    const params = {
      ...vendedor
    };

    this.setToken();
    return this.http.put(this.baseApiPath + "/api/pessoa/vendedor", JSON.stringify(params), { headers: this._headers });
  }

  updateFornecedor(fornecedor): Observable<any> {
    const params = {
      ...fornecedor
    };

    this.setToken();
    return this.http.put(this.baseApiPath + "/api/pessoa/fornecedor", JSON.stringify(params), { headers: this._headers });
  }

  updateConta(conta): Observable<any> {
    const params = {
      ...conta
    };

    this.setToken();
    return this.http.put(this.baseApiPath + "/api/pessoa/conta", JSON.stringify(params), { headers: this._headers });
  }

  updateTipoPessoa(tipoPessoa: TipoPessoa): Observable<any> {
    const params = {
      ...tipoPessoa
    };

    this.setToken();
    return this.http.put(this.baseApiPath + "/api/tipo-pessoa", JSON.stringify(params), { headers: this._headers });
  }

  addPessoaGeneric(pessoaGeneric): Observable<any> {
    const params = {
      ...pessoaGeneric
    };

    this.setToken();
    return this.http.post(this.baseApiPath + "/api/pessoa/generic", JSON.stringify(params), { headers: this._headers });
  }

  updatePessoaGeneric(pessoaGeneric, id): Observable<any> {
    const params = {
      ...pessoaGeneric
    };

    this.setToken();
    return this.http.put(this.baseApiPath + "/api/pessoa/generic/" + id, JSON.stringify(params), { headers: this._headers });
  }

  addClient(cliente): Observable<any> {
    let params = JSON.stringify({
      cpfCnpj: cliente.cpfCnpj,
      nome: cliente.nome,
      endereco: cliente.endereco,
      nro: cliente.nro,
      bairro: cliente.bairro,
      cidade: cliente.cidade,
      cMun: cliente.cMun,
      cep: cliente.cep,
      fone: cliente.fone,
      celular: cliente.celular,
      email: cliente.email,
      uf: cliente.uf,
      dataNascimento: cliente.dataNascimento,
      tipoPessoa: cliente.tipoPessoa,
      sexo: cliente.sexo,
      pessoaReferencia: cliente.pessoaReferencia,
      telefoneReferencia: cliente.telefoneReferencia,
      idIndicador: cliente.idIndicador,
      prospeccaoIndicador: cliente.prospeccaoIndicador,
      cpfPessoaReferencia: cliente.cpfPessoaReferencia,
      inscricaoEstadual: cliente.inscricaoEstadual
    });

    this.setToken();
    return this.http.post(this.baseApiPath + "/api/pessoa", params, { headers: this._headers });
  }

  addCliente(cliente): Observable<any> {
    let params = JSON.stringify({
      tipo: cliente.tipo,
      idOperacoes: cliente.idOperacoes,
      cpfCnpj: cliente.cpfCnpj,
      nome: cliente.nome,
      endereco: cliente.endereco,
      nro: cliente.nro,
      bairro: cliente.bairro,
      cidade: cliente.cidade,
      cMun: cliente.cMun,
      cep: cliente.cep,
      fone: cliente.fone,
      celular: cliente.celular,
      email: cliente.email,
      uf: cliente.uf,
      dataNascimento: cliente.dataNascimento,
      tipoPessoa: cliente.tipoPessoa,
      sexo: cliente.sexo,
      pessoaReferencia: cliente.pessoaReferencia,
      telefoneReferencia: cliente.telefoneReferencia,
      prospeccaoIndicador: cliente.prospeccaoIndicador,
      cpfPessoaReferencia: cliente.cpfPessoaReferencia,
      inscricaoEstadual: cliente.inscricaoEstadual
    });

    this.setToken();
    return this.http.post(this.baseApiPath + "/api/pessoa/cliente", params, { headers: this._headers });
  }

  addPdv(pdv): Observable<any> {
    let params = JSON.stringify({
      tipo: pdv.tipo,
      idOperacoes: pdv.idOperacoes,
      cpfCnpj: pdv.cpfCnpj,
      nome: pdv.nome,
      endereco: pdv.endereco,
      nro: pdv.nro,
      bairro: pdv.bairro,
      cidade: pdv.cidade,
      cMun: pdv.cMun,
      cep: pdv.cep,
      fone: pdv.fone,
      celular: pdv.celular,
      email: pdv.email,
      uf: pdv.uf,
      acessaNet: pdv.acessaNet,
      senhaNet: pdv.senhaNet,
      tipoPessoa: pdv.tipoPessoa,
      inscricaoEstadual: pdv.inscricaoEstadual,
    });

    this.setToken();
    return this.http.post(this.baseApiPath + "/api/pessoa/pdv", params, { headers: this._headers });
  }

  addIndicador(indicador): Observable<any> {
    let params = JSON.stringify({
      tipo: indicador.tipo,
      idOperacoes: indicador.idOperacoes,
      cpfCnpj: indicador.cpfCnpj,
      nome: indicador.nome,
      endereco: indicador.endereco,
      nro: indicador.nro,
      bairro: indicador.bairro,
      cidade: indicador.cidade,
      cMun: indicador.cMun,
      cep: indicador.cep,
      fone: indicador.fone,
      celular: indicador.celular,
      email: indicador.email,
      uf: indicador.uf,
      dataNascimento: indicador.dataNascimento,
      tipoPessoa: indicador.tipoPessoa,
      comissao: indicador.comissao,
    });

    this.setToken();
    return this.http.post(this.baseApiPath + "/api/pessoa/indicador", params, { headers: this._headers });
  }

  addGestor(gestor): Observable<any> {
    const params = {
      ...gestor
    };

    this.setToken();
    return this.http.post(this.baseApiPath + "/api/pessoa/gestor", JSON.stringify(params), { headers: this._headers });
  }

  addVendedor(vendedor): Observable<any> {
    const params = {
      ...vendedor
    };

    this.setToken();
    return this.http.post(this.baseApiPath + "/api/pessoa/vendedor", JSON.stringify(params), { headers: this._headers });
  }

  addFornecedor(fornecedor): Observable<any> {
    const params = {
      ...fornecedor
    };

    this.setToken();
    return this.http.post(this.baseApiPath + "/api/pessoa/fornecedor", JSON.stringify(params), { headers: this._headers });
  }

  addConta(conta): Observable<any> {
    const params = {
      ...conta
    };

    this.setToken();
    return this.http.post(this.baseApiPath + "/api/pessoa/conta", JSON.stringify(params), { headers: this._headers });
  }

  addTipoPessoa(tipoPessoa: TipoPessoa): Observable<any> {
    const params = {
      ...tipoPessoa
    };

    this.setToken();
    return this.http.post(this.baseApiPath + "/api/tipo-pessoa", JSON.stringify(params), { headers: this._headers });
  }

  addClientBasic(cliente): Observable<any> {
    let params = JSON.stringify({
      cpfCnpj: cliente.cpfCnpj,
      nome: cliente.nome,
      endereco: cliente.endereco,
      nro: cliente.nro,
      bairro: cliente.bairro,
      cidade: cliente.cidade,
      cep: cliente.cep,
      fone: cliente.fone,
      celular: cliente.celular,
      email: cliente.email,
      uf: cliente.uf,
      cMun: cliente.cMun,
      dataNascimento: this._utilsService.parseStringVazia(cliente.dataNascimento)
    });

    this.setToken();
    return this.http.post(this.baseApiPath + "/api/pessoa/basic", params, { headers: this._headers });
  }

  addPesquisaSeguro(prospeccaoSeguro: string, dataContato, idCliente): Observable<any> {
    let params = JSON.stringify({ descricao: prospeccaoSeguro, dataContato: dataContato, idCliente: idCliente });

    this.setToken();
    return this.http.post(this.baseApiPath + "/api/web-indicacao-vendas/seguro", params, { headers: this._headers });
  }

  addCompra(contratoId, finalizarPedidoNaoRentavel): Observable<any> {
    const params = { contratoId, finalizarPedidoNaoRentavel };
    this.setToken();
    return this.http.post(this.baseApiPath + '/api/compra', params, { headers: this._headers });
  }

  addPreVendaMoto(contratoId): Observable<any> {
    const params = contratoId;
    this.setToken();
    return this.http.post(this.baseApiPath + '/api/compra/pre-venda/moto', params, { headers: this._headers });
  }

  addCompraMoto(veiculoId, contratoId, nfeInfoAdicional): Observable<any> {
    let params = JSON.stringify({
      veiculoId: veiculoId,
      contratoId: contratoId,
      nfeInfoAdicional: nfeInfoAdicional
    });

    this.setToken();
    return this.http.post(this.baseApiPath + '/api/compra/moto', params, { headers: this._headers });
  }

  addWebContrato(valor, desconto, entrega, idCliente, idPagador): Observable<any> {
    let params = JSON.stringify({ valor: valor, desconto: desconto, entrega: entrega, cliente: { id: idCliente }, pagador: { id: idPagador } });

    this.setToken();
    console.log('params', params);
    return this.http.post(this.baseApiPath + "/api/web-contrato", params, { headers: this._headers });
  }

  deleteWebContrato(id): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/web-contrato/d/" + id, { headers: this._headers });
  }

  pausarCompra(webContratoId, produtos, flagMoto, contratoId): Observable<any> {
    const params = JSON.stringify({ webContratoId: webContratoId, produtos: produtos, flagMoto: flagMoto, contratoId: contratoId });

    this.setToken();
    return this.http.post(this.baseApiPath + '/api/web-contrato/produtos', params, { headers: this._headers });
  }

  saveWebContratoPagamentos(codigoCRM, pagamentos, tipoVenda): Observable<any> {
    let params = JSON.stringify({ idContrato: codigoCRM, pagamentos: pagamentos, tipoVenda: tipoVenda });

    this.setToken();
    return this.http.post(this.baseApiPath + "/api/web-contrato/pagamentos", params, { headers: this._headers });
  }

  checkContratoRentavel(contratoId): Observable<any> {
    console.log(contratoId)
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/compra/rentabilidade/" + contratoId, { headers: this._headers });
  }

  autorizarCreditoOnline(parcela: Payment, valorComJuros, contratoId): Observable<any> {
    let params = {
      contratoId: contratoId,
      valor: parcela.valor,
      valorComJuros: valorComJuros,
      qtdParcelas: parcela.qntParcelas,
      cartao: {
        pan: parcela.online.pan.replace(/ /g, ''),
        expiry_mm: parcela.online.mm,
        expiry_yyyy: parcela.online.yyyy,
        security_code: parcela.online.cvv,
      },
      titularCartao: {
        first_name: parcela.online.name_card,
        document_number: parcela.online.cpf
      }
    };

    if (!parcela.online.isBuyerOwnsCard) {
      params['endereco'] = {
        street: parcela.online.address.address + ' ' + parcela.online.address.number,
        neighborhood: parcela.online.address.neighborhood,
        city_name: parcela.online.address.city,
        state_code: parcela.online.address.state,
        postal_code: parcela.online.address.zipCode
      }
    }

    let paramsJson = JSON.stringify(params);

    this.setToken();
    return this.http.post(this.baseApiPath + "/api/lucree/pay", paramsJson, { headers: this._headers });
  }

  gerarNota(codigoCRM): Observable<any> {
    let pessoaId = 0;
    this.setToken();
    return this.http.post(this.baseApiNotaPath + '/api/nota', { contratoId: codigoCRM, pessoaId }, { headers: this._headers });
  }

  gerarNotaMoto(contratoId, infoFisco): Observable<any> {
    let pessoaId = 0;
    this.setToken();
    return this.http.post(this.baseApiPath + '/api/compra/moto/nota', { contratoId: contratoId, infoFisco }, { headers: this._headers });
  }

  retomarCompra(webContratoId): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/web-contrato/" + webContratoId, { headers: this._headers });
  }

  consultarNota(webContratoId): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiNotaPath + "/api/nota/" + webContratoId, { headers: this._headers });
  }

  // RELATÓRIOS
  getVendas(dataInicial, dataFinal, tipo, unidadeSelect, pdvSelect, idConvenio, matriz, idProduto, pageIndex, pageSize): Observable<any> {
    pageIndex = pageIndex ? pageIndex : 0;
    pageSize = pageSize ? pageSize : 30;

    return this.http.get(this.baseApiPath + '/api/relatorio/vendas/' + dataInicial + '/' + dataFinal + '/WEB/' + tipo + '/' + unidadeSelect + '/' + pdvSelect + '/' + idConvenio + '/' + matriz + '/' + idProduto + '/' + pageIndex + '/' + pageSize);
  }

  getStatsHeaderVendasVendas(dataInicial, dataFinal, tipoMes, tipo, unidadeSelect, pdvSelect, idConvenio, matriz, idProduto): Observable<any> {
    this.setToken();

    return this.http.get(this.baseApiPath + '/api/relatorio/vendas/stats/' + dataInicial + '/' + dataFinal + '/' + tipoMes + '/' +
      tipo + '/' + unidadeSelect + '/' + pdvSelect + '/' + idConvenio + '/' + matriz + '/' + idProduto, { headers: this._headers });
  }

  getVendasID(idContratoBusca): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/relatorio/vendas/" + idContratoBusca, { headers: this._headers });
  }

  getVenda(idContrato): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/relatorio/venda/" + idContrato, { headers: this._headers });
  }

  getPDVs(roleUser, unidade): Observable<any> {
    if ((roleUser === Perfis.PDV) || unidade == null || unidade === 'undefined') {
      return this.http.get(this.baseApiPath + '/api/relatorio/pdvs');
    } else if (unidade != null && unidade !== 'undefined') {
      return this.http.get(this.baseApiPath + '/api/relatorio/pdvs/' + unidade);
    }
  }

  getPDVsAdm(unidade): Observable<any> {
    return this.http.get(this.baseApiPath + '/api/relatorio/pdvs/' + unidade).pipe(map(res => res['data']));
  }

  getSeguros(dataInicial, dataFinal, unidadeSelect, pdvSelect): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/relatorio/seguros/" + dataInicial + "/" + dataFinal + "/" + unidadeSelect + "/" + pdvSelect, { headers: this._headers });
  }

  getIndicacoes(dataInicial, dataFinal, statusIndicacaoSelect, convenioSelect, unidadeSelect, pdvSelect, flagDataAtualizacao, supervisorSelect): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/web-indicacao-vendas/" + dataInicial + "/" + dataFinal + "/" + statusIndicacaoSelect + "/" + convenioSelect + "/" + unidadeSelect + "/" + pdvSelect + "/" + flagDataAtualizacao + "/" + supervisorSelect, { headers: this._headers });
  }

  getIndicacoesParaAgendamento(): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/web-indicacao-vendas", { headers: this._headers });
  }

  deleteIndicacao(id): Observable<any> {
    this.setToken();
    return this.http.delete(this.baseApiPath + "/api/web-indicacao-vendas/" + id, { headers: this._headers });
  }

  addIndicacao(indicacao: Indicacao): Observable<any> {
    let params = JSON.stringify({
      nome: indicacao.nome,
      telefone: indicacao.telefone,
      email: indicacao.email,
      celular: indicacao.celular,
      cep: indicacao.cep,
      endereco: indicacao.endereco,
      bairro: indicacao.bairro,
      cidade: indicacao.cidade,
      uf: indicacao.uf,
      descricao: indicacao.descricao,
      idConvenio: indicacao.idConvenio,
      idPessoa: indicacao.idPessoa,
      idOperacoes: indicacao.idOperacoes
    });

    this.setToken();
    return this.http.post(this.baseApiPath + "/api/web-indicacao-vendas", params, { headers: this._headers });
  }

  updateIndicacao(indicacao): Observable<any> {
    let params = JSON.stringify({
      id: indicacao.id,
      nome: indicacao.nome,
      telefone: indicacao.telefone,
      email: indicacao.email,
      celular: indicacao.celular,
      cep: indicacao.cep,
      endereco: indicacao.endereco,
      bairro: indicacao.bairro,
      cidade: indicacao.cidade,
      uf: indicacao.uf,
      descricao: indicacao.descricao,
      idConvenio: indicacao.idConvenio,
      idPessoa: indicacao.idPessoa,
      idOperacoes: indicacao.idOperacoes
    });

    this.setToken();
    return this.http.put(this.baseApiPath + "/api/web-indicacao-vendas", params, { headers: this._headers });
  }

  getIndicacoesCpfCnpj(cpfCnpj): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/web-indicacao-vendas/" + cpfCnpj, { headers: this._headers });
  }

  // métodos para agendamento de indicacao

  getAgendamentos(dateRest, unidadeSelect, pdvSelectFiltro): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/web-agendamento-indicacao/agenda/' + dateRest + '/' + unidadeSelect + '/' + pdvSelectFiltro, { headers: this._headers });
  }

  getHorariosDisponiveisPDV(dataAgendamento, idPDV): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/web-agendamento-indicacao/" + dataAgendamento + "/" + idPDV, { headers: this._headers });
  }

  getOportunidadeVenda(id): Observable<any> {
    return this.http.get(this.baseApiPath + "/api/web-indicacao-vendas/indicacao/" + id);
  }

  addUpdAgendamento(agendamento: Agendamento): Observable<any> {
    let paramsObj = {
      idIndicacao: agendamento.indicacao,
      dataAgendamento: agendamento.dataAgendamento,
      idPdv: agendamento.pdv,
      acaoDesejada: agendamento.acaoDesejada
    };

    this.setToken();
    if (agendamento.id == undefined || agendamento.id == null) {
      return this.http.post(this.baseApiPath + "/api/web-agendamento-indicacao", JSON.stringify(paramsObj), { headers: this._headers });
    }
    else {
      paramsObj["id"] = agendamento.id;
      return this.http.put(this.baseApiPath + "/api/web-agendamento-indicacao", JSON.stringify(paramsObj), { headers: this._headers });
    }
  }

  deleteAgendamento(id): Observable<any> {
    this.setToken();
    return this.http.delete(this.baseApiPath + "/api/web-agendamento-indicacao/" + id, { headers: this._headers });
  }

  updateAgendamento(agendamento: Agendamento): Observable<any> {
    let params = JSON.stringify({
      id: agendamento.id,
      idIndicacao: agendamento.indicacao,
      dataAgendamento: agendamento.dataAgendamento,
      idPdv: agendamento.pdv,
      acaoDesejada: agendamento.acaoDesejada
    });

    this.setToken();
    return this.http.put(this.baseApiPath + "/api/web-agendamento-indicacao", params, { headers: this._headers });
  }

  getIndicacoesMes(mes, ano): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/indicacaomes/" + mes + "/" + ano, { headers: this._headers });
  }

  getAgendamentosPorIndicacao(idIndicacao): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/atendimento/indicacao/" + idIndicacao, { headers: this._headers });
  }

  addComentario(comentario): Observable<any> {
    let params = JSON.stringify(comentario);
    this.setToken();
    return this.http.post(this.baseApiPath + "/api/web-agendamento-indicacao/comentario", params, { headers: this._headers });
  }

  addComentarioOportunidade(comentario): Observable<any> {
    return this.http.post(this.baseApiPath + "/api/web-indicacao-vendas/comentario", JSON.stringify(comentario));
  }

  getConsultas(): Observable<Consulta[]> {
    this.setToken();
    return this.http.get<Consulta[]>(this.baseApiPath + '/api/consultacadastral', { headers: this._headers });
  }

  addConsultaInfo(webContratoId: String, cpfCnpj: String): Observable<any> {
    const params = JSON.stringify({ webContratoId, cpfCnpj });
    this.setToken();
    return this.http.post(this.baseApiPath + '/api/consultacadastral/info', params, { headers: this._headers });
  }

  gerarPDFConsulta(contratoId): Observable<String> {
    this.setToken();
    return this.http.get<String>(this.baseApiPath + '/api/consultacadastral/pdf/' + contratoId, { headers: this._headers });
  }

  gerarPropostaSolar(idIndicacaoVenda, idProduto, valor, chance): Observable<String> {
    this.setToken();
    return this.http.post<String>(this.baseApiPath + '/api/propostasolar/' + idIndicacaoVenda + '/kit-solar/' + idProduto + '/valor/' + valor + '/chance/' + chance, { headers: this._headers });
  }

  setProposta(idWebIndicacaoVenda, idProduto, valor, chance): Observable<any> {
    const params = {
      "produto": { "id": idProduto },
      "valor": valor,
      "chance": chance
    };
    console.log('idWebIndicacaoVenda', idWebIndicacaoVenda, params);
    return this.http.post<any>(this.baseApiPath + '/api/propostas/' + idWebIndicacaoVenda, params);
  }

  downloadPDFSolar(idIndicacaoVenda): Observable<String> {
    this.setToken();
    return this.http.get<String>(this.baseApiPath + '/api/propostasolar/pdf/' + idIndicacaoVenda, { headers: this._headers });

  }

  pegarPDFExemplo(produtoId): Observable<String> {
    this.setToken();
    return this.http.get<String>(this.baseApiPath + '/api/consultacadastral/pdf/exemplo/' + produtoId, { headers: this._headers });
  }

  getPromocoes(): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/promocao/", { headers: this._headers });
  }

  encerrarOportunidade(idIndicacao, statusIndicacao): Observable<any> {
    const params = JSON.stringify({
      id: idIndicacao,
      status: statusIndicacao
    });

    this.setToken();
    return this.http.put(this.baseApiPath + '/api/web-indicacao-vendas/encerrar', params, { headers: this._headers });
  }

  aprovarOportunidade(idIndicacao): Observable<any> {
    const params = JSON.stringify({
      id: idIndicacao,
      status: 'INDICACAO_APROVADA'
    });

    this.setToken();
    return this.http.put(this.baseApiPath + '/api/web-indicacao-vendas/aprovar', params, { headers: this._headers });
  }

  retomarOportunidade(idIndicacao, statusIndicacao): Observable<any> {
    const params = JSON.stringify({
      id: idIndicacao,
      status: statusIndicacao
    });

    this.setToken();
    return this.http.put(this.baseApiPath + '/api/web-indicacao-vendas/retomar', params, { headers: this._headers });
  }

  getOportunidadeStatsStatus(dataInicial, dataFinal, unidadeSelect, pdvSelect, idConvenio, flagDataAtualizacao): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/web-indicacao-vendas/status/' + dataInicial + '/' + dataFinal + '/' + unidadeSelect + '/' + pdvSelect + '/' + idConvenio + '/' + flagDataAtualizacao, { headers: this._headers });
  }

  importXML(arquivoXML): Observable<any> {
    const params = JSON.stringify(arquivoXML);

    return this.http.post(this.baseApiPath + '/api/notaproduto', params, { headers: this._headers }).pipe(
      map(res => res['data']));
  }

  avaliarNota(notaId, status): Observable<any> {
    const params = JSON.stringify({
      id: notaId,
      status: status
    });

    return this.http.put(this.baseApiPath + '/api/notaproduto', params, { headers: this._headers }).pipe(
      map(res => res));
  }

  getMotosPreVenda(): Observable<VeiculoPreVenda[]> {
    return this.http.get<VeiculoPreVenda[]>(this.baseApiPath + '/api/veiculo/pre-vendas')
                .pipe(map(res => res['data']));
  }

  getMotoPreVendaByProdutoId(produtoId: number): Observable<VeiculoPreVenda> {
    return this.http.get<VeiculoPreVenda>(this.baseApiPath + `/api/veiculo/pre-venda/veiculo/${produtoId}`)
                .pipe(map(res => res['data']));
  }

  putVeiculoPreVenda(produtoId: number, ativo: boolean): Observable<string> {
    return this.http.put<string>(this.baseApiPath + `/api/veiculo/pre-venda/veiculo/ativacao/${produtoId}`, ativo)
                .pipe(map(res => res['data']));
  }

  getMotosPreVendaAtivasVisualizacao(): Observable<Moto[]> {
    this.setToken();
    return this.http.get<Moto[]>(this.baseApiPath + '/api/veiculo/pre-vendas/ativas', { headers: this._headers });
  }

  getMotosPreVendasAtivas(): Observable<any[]> {
    this.setToken();
    return this.http.get<any[]>(this.baseApiPath + '/api/veiculo/pre-venda/ativa', { headers: this._headers });
  }

  getMotosPreVendasFiltro(pdvId, dataInicial, dataFinal, unidadeSelect, matriz): Observable<PreVenda[]> {
    this.setToken();
    return this.http.get<PreVenda[]>(this.baseApiPath + '/api/veiculo/pre-venda/' + pdvId + '/' + dataInicial + '/' + dataFinal + '/' + unidadeSelect + '/' + matriz, { headers: this._headers });
  }

  realizarTrocaVeiculoPreVenda(contratoId: number, newVeiculoId: number) {
    const body = {
      idProduto: newVeiculoId
    }

    return this.http.put<any>(this.baseApiPath + '/api/veiculo/pre-venda/' + contratoId, body);
  }

  getListaMotos(): Observable<Moto[]> {
    this.setToken();
    return this.http.get<Moto[]>(this.baseApiPath + '/api/veiculo/all', { headers: this._headers });
  }

  checkMotoAtiva(motoId): Observable<String> {
    this.setToken();
    return this.http.get<String>(this.baseApiPath + '/api/veiculo/ativo/' + motoId, { headers: this._headers });
  }

  importNotaImagem(veiculoId, destinoId, valorNota, arquivoNota): Observable<any> {
    const params = JSON.stringify({
      veiculoId: veiculoId,
      destinoId: destinoId,
      valorNota: valorNota,
      fileImportDto: arquivoNota
    });

    return this.http.put(this.baseApiPath + '/api/veiculo', params, { headers: this._headers });
  }

  getMoto(motoId): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/veiculo/' + motoId, { headers: this._headers });
  }

  getMotoContrato(contratoId): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/veiculo/idContrato/' + contratoId, { headers: this._headers });
  }

  getMensagemPadraoFisco(contratoId) {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/veiculo/fisco/' + contratoId, { headers: this._headers });
  }

  downloadDanfeNota(codigoCRM) {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/notaproduto/" + codigoCRM, { headers: this._headers });
  }

  getLogs(): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/log", { headers: this._headers });
  }

  /**
   * SELECT
   */
  getPessoaPorTipo(tipo): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/select/pessoa/" + tipo, { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  getConvenios(): Observable<any> {
    return this.http.get(`${this.baseApiPath}/api/select/convenio`);
  }

  getUnidades(): Observable<any> {
    return this.http.get(this.baseApiPath + "/api/select/operacoes");
  }

  getUnidadesByMatriz(idMatriz: number): Observable<any> {
    return this.http.get(this.baseApiPath + `/api/select/operacoes/matriz/motos/${idMatriz}`);
  }

  getMatrizByGestor(): Observable<any> {
    return this.http.get(this.baseApiPath + "/api/operacoes/gestor").pipe(map(res => {
      const r = res['data'];
      return r.map(matriz => {
        return {
          idMatriz: matriz.matrizMotoId,
          idOperacaoEmpresa: matriz.id,
          text: matriz.descricao
        }
      })
    }));
  }

  getUnidadesByMatrizByGestor(idMatriz: number): Observable<any> {
    return this.http.get(this.baseApiPath + `/api/operacoes/gestor/${idMatriz}`).pipe(map(res => {
      const r = res['data'];
      return r.map(unidade => {
        return {
          id: unidade.id,
          text: unidade.descricao
        }
      })
    }));
  }

  getSupervisores(): Observable<any> {
    return this.http.get(this.baseApiPath + "/api/select/supervisores");
  }

  /* BEGIN EXTRATO */
  getMovimentacoes(dataInicial, dataFinal, pessoaId): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/financeiro/" + dataInicial + "/" + dataFinal + "/" + pessoaId, { headers: this._headers }).pipe(
      map(res => res['data']))
  }
  getMovimentacao(dataInicial, dataFinal, pessoaId): Observable<any> {
    return this.http.get(this.baseApiPath + "/api/financeiro/" + dataInicial + "/" + dataFinal + "/" + pessoaId, { headers: this._headers });
  }

  getPessoasDetalhe(tipo, dataInicial, dataFinal): Observable<any> {
    return this.http.get(this.baseApiPath + "/api/financeiro/pessoa-detalhe-mv/" + tipo + "/" + dataInicial + "/" + dataFinal, { headers: this._headers });
  }
  /* END EXTRATO */

  enviarEmailLinkPagamento(codigoCRM, email, valor, pagamentoId) {
    this.setToken();
    return this.http.post(this.baseApiPath + "/api/pagamento/email", { contratoId: codigoCRM, email, valor, pagamentoId }, { headers: this._headers });
  }

  pagamentosPorContrato(contratoId) {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/pagamento/todos/" + contratoId, { headers: this._headers });
  }

  /* END CONCILIAR */
  controleAcesso(perfil: string) {
    const currentPermissions = this.controleDeAcesseObservable.getValue();

    if (!currentPermissions) {
      this.http.get(this.baseApiPath + "/api/menus/controle-acesso/" + this.SISTEMA + '/' + perfil).pipe(
        map(res => {
          let permissoes = res['data'];
          return this.mapPermissoesToObject(permissoes);
        }),
        tap(permissoes => {
          this.controleDeAcesseObservable.next(permissoes);
        }),
        take(1)
      ).subscribe();
    }
  }

  mapPermissoesToObject(permissoes: {}) {
    return {
      relatorios: permissoes['RELATÓRIOS'],
      relatorioDeVendas: permissoes['RELATÓRIO DE VENDAS'],
      relatorioDeIndicacoes: permissoes['RELATÓRIO DE INDICAÇÕES'],
      oportunidadeDeVendas: permissoes['OPORTUNIDADES DE VENDAS'],
      oportunidades: permissoes['OPORTUNIDADES'],
      financeiro: permissoes['FINANCEIRO'],
      extrato: permissoes['EXTRATO'],
      lancamentos: permissoes['LANÇAMENTOS'],
      conciliacao: permissoes['CONCILIAÇÃO'],
      tarifasCartoes: permissoes['TARIFAS CARTÕES'],
      cadastros: permissoes['CADASTROS'],
      produtos: permissoes['PRODUTOS'],
      operacaoEmpresa: permissoes['OPERAÇÃO EMPRESA'],
      menusPorPerfil: permissoes['MENUS POR PERFIL'],
      pessoas: permissoes['PESSOAS'],
      pontoDeVenda: permissoes['PONTO DE VENDA'],
      vendas: permissoes['VENDAS'],
      certificadosDigitais: permissoes['CERTIFICADOS DIGITAIS'],
      consultasCadastrais: permissoes['CONSULTAS CADASTRAIS'],
      motos: permissoes['MOTOS'],
      servicosLogisticos: permissoes['SERVIÇOS LOGÍSTICOS'],
      pesquisar: permissoes['PESQUISAR'],
      tipoPessoa: permissoes['TIPO PESSOA'],
      motos_adm: permissoes['MOTOS-ADM'],
      importacao: permissoes['IMPORTAÇÃO DE LANÇAMENTOS'],
      bancos: permissoes['BANCOS'],
      canais: permissoes['CANAIS'],
      classes: permissoes['CLASSES'],
      convenios: permissoes['CONVÊNIOS'],
      politicas: permissoes['POLÍTICAS'],
      usuarios: permissoes['USUÁRIOS'],
      lotePagamentos: permissoes['LOTE DE PAGAMENTOS'],
      veiculosPreVenda: permissoes['VEÍCULOS PRÉ-VENDA']
    } as Menu;
  }

  getMenusPerfil(perfil): Observable<any> {
    return this.http.get(this.baseApiPath + "/api/select/menus/ativos/" + this.SISTEMA + '/' + perfil);
  }

  getPerfis(): Observable<any> {
    return this.http.get(this.baseApiPath + "/api/perfil");
  }

  getPerfilById(idPerfil: number): Observable<any> {
    return this.http.get(this.baseApiPath + "/api/perfil/" + idPerfil);
  }

  getMenus(): Observable<any> {
    const sistema = 'VALORIZA_WEB'

    return this.http.get(this.baseApiPath + "/api/menus/" + sistema);
  }

  getMenusVizualizacao(idPerfil: number): Observable<any> {
    return this.http.get(this.baseApiPath + "/api/menus/vizualizacao/" + this.SISTEMA + '/' + idPerfil);
  }

  updateMenuPerfil(listaMenus) {
    return this.http.post(this.baseApiPath + '/api/web-perfil-menu/', listaMenus).pipe(
      map(res => {
        const permissoes = res['data'];
        return this.mapPermissoesToObject(permissoes);
      }),
      tap(permissoes => {
        this.controleDeAcesseObservable.next(permissoes);
      }),
      take(1)
    ).subscribe(
      _ => {
        this.modalService.abrirModalSucesso("Editar Perfil do Menu", "A ação foi efetuada com sucesso!")
      }, error => {
        this._utilsService.alertErro(error);
      }
    );
  }

  limparPermissoes() {
    this.controleDeAcesseObservable.next(null);
  }

  buscaPrazo(idWebContrato): Observable<any> {
    return this.http.get(this.baseApiPath + "/api/pre-venda/" + idWebContrato, { headers: this._headers });
  }

  buscarVeiculosPreVenda(idPDV): Observable<any> {
    return this.http.get(this.baseApiPath + "/api/veiculo/pre-venda/" + idPDV).pipe(
      map(res => res['data']));
  }

  getHistoricoSoluti(webContratoId): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/relatorio/soluti/' + webContratoId, { headers: this._headers });
  }

  getMotos(): Observable<Moto[]> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/veiculo', { headers: this._headers }).pipe(
      map(res => res['data']));
  }

  getListaMotosAdm(dataInicial, dataFinal, tipo, pdvSelectLista, unidadeSelect, matriz): Observable<Moto[]> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/veiculo/' + dataInicial + '/' + dataFinal + '/' + tipo + '/' + unidadeSelect + '/' + pdvSelectLista + '/' + matriz, { headers: this._headers }).pipe(
      map(res => res['data']));
  }

  checkMotoAtivaAdm(motoId): Observable<string> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/veiculo/ativo/' + motoId, { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  movimentarVeiculo(veiculoId, destinoId, valor, isMontada): Observable<any> {
    const params = JSON.stringify({
      veiculoId: veiculoId,
      destinoId: destinoId,
      valor: valor,
      isMontada: isMontada
    });

    return this.http.put(this.baseApiPath + '/api/veiculo', params, { headers: this._headers }).pipe(
      map(res => res));
  }

  transferirMotosEmLote(transferenciaForm: TransferenciaMultipla): Observable<any> {
    this.setToken();
    return this.http.put(this.baseApiPath + '/api/veiculo/transferencia-em-lote', transferenciaForm);
  }

  movimentarVeiculoSemNota(veiculoId, destinoId, valor): Observable<any> {
    const params = JSON.stringify({
      veiculoId: veiculoId,
      destinoId: destinoId,
      valor: valor
    });

    return this.http.put(this.baseApiPath + '/api/veiculo/semnota', params, { headers: this._headers }).pipe(
      map(res => res));
  }

  getVeiculoChassi(chassi): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/veiculo/chassi/" + chassi, { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  getVeiculoNF(numNF): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/veiculo/nf/" + numNF, { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  getVeiculoHistorico(chassi): Observable<any> {
    return this.http.get(`${this.baseApiPath}/api/veiculo/historico/${chassi}`).pipe(
      map(res => res['data']))
  }

  getOperacoesSelect(): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/operacoes/select", { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  getOperacoes(): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/operacoes", { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  getOperacao(): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/operacao", { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  getOperacaoByTipoPessoaId(tipoPessoaId: number): Observable<OperacaoFinanceiraResume[]> {
    return this.http.get(`${this.baseApiPath}/api/operacao/tipo/id/${tipoPessoaId}`).pipe(
      map(res => res['data']));
  }

  getOperacaoPessoas(operacaoId: number): Observable<IPeopleOperation[]> {
    return this.http.get(this.baseApiPath + "/api/operacao/pessoa/" + operacaoId).pipe(
      map(res => res['data']))
  }

  getTiposPessoa(): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/pessoa/tipo", { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  getTipoPorOperacao(idOperacao: number): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/operacao/" + idOperacao, { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  postMovimentacao(movimentacao): Observable<any> {
    const params = JSON.stringify(movimentacao);

    return this.http.post(this.baseApiPath + '/api/movimentacao', params, { headers: this._headers }).pipe(
      map(res => res['data']));
  }

  getPessoa(id): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/pessoa/id/' + id, { headers: this._headers }).pipe(
      map(res => res["data"]));
  }

  cancelarVenda(veiculoId): Observable<any> {
    const params = JSON.stringify({
      veiculoId: veiculoId
    });

    return this.http.put(this.baseApiPath + '/api/veiculo/cancelarvenda', params, { headers: this._headers }).pipe(
      map(res => res));

  }

  cancelarVendaConciliada(veiculoId): Observable<any> {
    const params = JSON.stringify({
      veiculoId: veiculoId
    });

    return this.http.put(this.baseApiPath + '/api/veiculo/cancelarvendaconciliada', params, { headers: this._headers }).pipe(
      map(res => res));

  }

  corrigirNotaFiscal(veiculoId): Observable<any> {
    const params = JSON.stringify({
      veiculoId: veiculoId
    });

    return this.http.put(this.baseApiPath + '/api/veiculo/corrigirnotafiscal', params).pipe(
      map(res => res));

  }

  downloadXMLNota(codigoCRM) {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/notaproduto/" + codigoCRM, { headers: this._headers }).pipe(
      map(res => res['data']));
  }

  downloadXML(codigoCRM) {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/notaproduto/downloadXML/" + codigoCRM, { headers: this._headers }).pipe(
      map(res => res['data']));
  }

  // Pré-venda
  deletePrevenda(id): Observable<any> {
    return this.http.delete(this.baseApiPath + '/api/veiculo/pre-venda/' + id, { headers: this._headers }).pipe(
      map(res => res));
  }

  getAllClassesProdutos(): Observable<ClasseProduto[]> {
    return this.http.get(this.baseApiPath + "/api/classes-produtos").pipe(
      map(res => res['data'])
    );
  }

  getWebCertificadoTipo(): Observable<WebCertificadoTipo[]> {
    return this.http.get(this.baseApiPath + "/api/web-certificado-tipo").pipe(
      map(res => res['data'])
    );
  }

  getWebCertificadoTipoPessoa(): Observable<WebCertificadoTipoPessoa[]> {
    return this.http.get(this.baseApiPath + "/api/web-certificado-tipo-pessoa").pipe(
      map(res => res['data'])
    );
  }

  getClasseProduto(id: number): Observable<Banco> {
    return this.http.get(this.baseApiPath + '/api/classes-produtos/' + id).pipe(
      map(res => res['data']));
  }

  addClasseProduto(classeProdutoForm: CadastroClasseProduto): Observable<ClasseProduto> {
    return this.http.post(this.baseApiPath + "/api/classes-produtos", classeProdutoForm).pipe(
      map(res => res['data']));
  }

  updateClasseProduto(classeProduto: ClasseProduto): Observable<ClasseProduto> {
    return this.http.put(this.baseApiPath + "/api/classes-produtos", classeProduto).pipe(
      map(res => res['data']));
  }

  // /produto
  getProdutos(): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/produto/", { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  criarProduto(form: ProdutoNovo): Observable<any> {
    this.setToken();
    return this.http.post(this.baseApiPath + '/api/produto/cadastrar', form);
  }

  atualizarProduto(form: ProdutoNovo): Observable<any> {
    this.setToken();
    return this.http.put(this.baseApiPath + '/api/produto/atualizar', form);
  }

  getAllProdutos(): Observable<ProdutoNovo[]> {
    return this.http.get(this.baseApiPath + "/api/produto/all").pipe(
      map(res => res['data']));;
  }

  getProdutoAtivosByIdConvenio(idConvenio: number): Observable<any> {
    return this.http.get(this.baseApiPath + `/api/select/produto/ativos/${idConvenio}`, { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  getClasses(): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/produto/classes/", { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  getProdutoAtivoClasse(id_classe): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/produto/ativo/classe/" + id_classe, { headers: this._headers });
  }

  getKitSolar(): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/kit_solar", { headers: this._headers });
  }

  getPromocoesProduto(produtoId): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/promocao/' + produtoId, { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  deleteProduto(id): Observable<any> {
    return this.http.delete(this.baseApiPath + '/api/produto/' + id, { headers: this._headers }).pipe(
      map(res => res));
  }

  getConsultaCadastral(produtoId): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/consultacadastral/produto/' + produtoId, { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  getAtributosMotos(produtoId) {
    return this.http.get(this.baseApiPath + '/api/produto/atributo/moto/' + produtoId, { headers: this._headers }).pipe(
      map(res => res));
  }

  getProduto(produto): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/produto/" + produto.produtoId, { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  getProdutoPorId(id): Observable<ProdutoNovo> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/produto/produtoId/' + id, { headers: this._headers }).pipe(
      map(res => res['data']));
  }

  reativarPromocao(promo) {
    let params = JSON.stringify(promo.id);

    return this.http.post(this.baseApiPath + '/api/promocao/recriar', params, { headers: this._headers }).pipe(
      map(res => res));
  }

  desativarPromocao(promo) {
    let params = JSON.stringify(promo);

    return this.http.put(this.baseApiPath + '/api/promocao/parar', promo.id, { headers: this._headers }).pipe(
      map(res => res));
  }

  updateProdutoTabela(produto) {
    let params = JSON.stringify(produto);

    return this.http.put(this.baseApiPath + '/api/produto/tabela', params, { headers: this._headers }).pipe(
      map(res => res));
  }

  criarConsultarCadastral(idWebservice) {
    let params = JSON.stringify(idWebservice);

    return this.http.post(this.baseApiPath + '/api/consultacadastral', params, { headers: this._headers }).pipe(
      map(res => res));
  }

  updateAtributosMotos(produto) {
    let params = JSON.stringify(produto);

    return this.http.put(this.baseApiPath + '/api/produto/atributo/moto', params, { headers: this._headers }).pipe(
      map(res => res));
  }

  addProduto(produto, imagemBase64): Observable<any> {
    if (produto.ativo === 'S') {
      produto.ativo = true;
    } else {
      produto.ativo = false;
    }

    produto.imagemBase64 = imagemBase64;
    const params = JSON.stringify(produto);

    return this.http.post(this.baseApiPath + '/api/produto/', params, { headers: this._headers }).pipe(
      map(res => res['data']));
  }

  updateProduto(produto, imagemBase64) {
    produto.imagemBase64 = imagemBase64;
    let params = JSON.stringify(produto);

    return this.http.put(this.baseApiPath + '/api/produto/', params, { headers: this._headers }).pipe(
      map(res => res));
  }

  addPromocao(produtoId, descontoPorcentagem): Observable<any> {
    const params = JSON.stringify({ produtoId: produtoId, descontoPorcentagem: descontoPorcentagem });

    return this.http.post(this.baseApiPath + "/api/promocao/", params, { headers: this._headers }).pipe(
      map(res => res));
  }

  addOperacaoEmpresa(operacaoEmpresa) {
    // const params = JSON.stringify(operacaoEmpresa);

    return this.http.post(this.baseApiPath + '/api/operacoes/', operacaoEmpresa).pipe(
      map(res => res['data']));
  }

  updateOperacaoEmpresa(operacaoEmpresa) {
    // let params = JSON.stringify(operacaoEmpresa);
    return this.http.put(this.baseApiPath + '/api/operacoes/', operacaoEmpresa).pipe(
      map(res => res));
  }

  getCidadesNfse(): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/nota/", { headers: this._headers }).pipe(
      map(res => res['data']))
  }


  deleteOperacaoEmpresa(id): Observable<any> {
    return this.http.delete(this.baseApiPath + '/api/operacoes/' + id, { headers: this._headers }).pipe(
      map(res => res));
  }

  getOperacaoEmpresa(operacoesId): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/operacoes/" + operacoesId, { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  /**
   * SELECT
   */
  getBancos(): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/select/banco").pipe(
      map(res => res['data']))
  }

  getAllBancos(): Observable<any> {
    return this.http.get(this.baseApiPath + "/api/banco").pipe(
      map(res => res['data']));
  }

  getBanco(id: number): Observable<Banco> {
    return this.http.get(this.baseApiPath + '/api/banco/' + id).pipe(
      map(res => res['data']));
  }

  addBanco(bancoForm: CadastroBanco): Observable<Banco> {
    return this.http.post(this.baseApiPath + "/api/banco", bancoForm).pipe(
      map(res => res['data']));
  }

  updateBanco(bancoForm: Banco): Observable<Banco> {
    const params = {
      ...bancoForm
    };
    this.setToken();
    return this.http.put(this.baseApiPath + "/api/banco", JSON.stringify(params)).pipe(
      map(res => res['data']));
  }

  getAllConvenios(): Observable<Convenio []> {
    return this.http.get(this.baseApiPath + "/api/convenio").pipe(
      map(res => res['data']));
  }
  
  getConvenio(id: number): Observable<Convenio> {
    return this.http.get( this.baseApiPath +'/api/convenio/'+ id).pipe(
    map(res => res['data']));
  }

  addConvenio(convenioForm: CadastroConvenio): Observable<Convenio> {
    return this.http.post(this.baseApiPath + '/api/convenio', convenioForm).pipe(
      map(res => res['data']));
  }

  updateConvenio(convenioForm: Convenio): Observable<Convenio> {
    const params = {
     ...convenioForm 
    };
    this.setToken();
    return this.http.put( this.baseApiPath + '/api/convenio/', JSON.stringify(params)).pipe(
    map(res => res['data']));
  }

  getAllTipos(): Observable<Tipo[]> {
    return this.http.get(this.baseApiPath + "/api/tipos/tipos").pipe(
      map(res => res['data']));
  }

  getAllSubTipos(): Observable<Tipo[]> {
    return this.http.get(this.baseApiPath + "/api/tipos/subtipos").pipe(
      map(res => res['data']));
  }

  getPoliticaPorId(id: number): Observable<PoliticaProduto> {
    return this.http.get(this.baseApiPath + "/api/politica-produtos/" + id).pipe(
      map(res => res['data']));
  }
  getAllPoliticaProdutos(): Observable<any> {
    return this.http.get(this.baseApiPath + "/api/politica-produtos").pipe(
      map(res => res['data']));
  }

  getPoliticasComPaginacao(page: number, size: number, search: string, filter: string): Observable<any> {
    return this.http.get(`${this.baseApiPath + "/api/politica-produtos/page"}`, {
      params: {
        pageNo: page.toString(),
        pageSize: size.toString(),
        search: search,
        filter: filter
      }
    }).pipe(map(res => res['data']));
  }

  addPolitica(politicaForm: PoliticaProduto): Observable<PoliticaProduto> {
    return this.http.post(this.baseApiPath + "/api/politica-produtos", politicaForm).pipe(
      map(res => res['data']));
  }

  atualizarPolitica(politicaForm: PoliticaProduto): Observable<PoliticaProduto> {
    const params = {
      ...politicaForm
    };
    this.setToken();
    return this.http.put(this.baseApiPath + "/api/politica-produtos", JSON.stringify(params)).pipe(
      map(res => res['data']));
  }

  getMatrizes(tipo: number): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/select/matriz/" + tipo, { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  getMatrizAtivas(tipo: number): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/select/matriz/ativas/" + tipo, { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  getOperacoesTipo(): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/select/operacao/tipo", { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  getOperacoesSubtipo(): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/select/operacao/subtipo", { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  getProdutosConvenio(convenioId): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + "/api/select/produto/convenio/" + convenioId, { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  getBancoOperacoes(): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/select/produto/banco', { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  getOperacoesContas(): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/select/conta', { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  /* BEGIN CONCILIAR */
  getPagamentosConciliar(filtro, pageIndex, pageSize, sortActive, sortDirection): Observable<any> {
    this.setToken();
    console.log(filtro)
    return this.http.get(this.baseApiPath + '/api/pagamento/conciliar/' +
      this._utilsService.formatDateDatable(filtro.dataInicial.date) + '/' +
      this._utilsService.formatDateDatable(filtro.dataFinal.date) + '/' +
      filtro.operacoesId + '/' +
      filtro.pdvId + '/' +
      filtro.clienteId + '/' +
      filtro.contratoId + '/' +
      pageIndex + '/' +
      pageSize + '/' +
      sortActive + '/' +
      sortDirection, { headers: this._headers });
  }

  postConciliar(conciliacao): Observable<any> {
    this.setToken();
    return this.http.post(this.baseApiPath + '/api/pagamento/conciliar',
      {
        pagamentoId: conciliacao.pagamentoId,
        tarifa: conciliacao.tarifa,
        juro: conciliacao.juro,
        conta: conciliacao.conta,
        dataRecebimento: this._utilsService.formatDate(conciliacao.dataRecebimento.date)
      }, { headers: this._headers });
  }

  getContasMatriz(id_contrato): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/pagamento/conciliar/contas/' + id_contrato, { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  getComprovantePagamento(pagamentoId): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/pagamento/comprovante/' + pagamentoId, { headers: this._headers });
  }

  updateComprovantePagamento(pagamentoId, comprovante): Observable<any> {
    this.setToken();
    return this.http.put(this.baseApiPath + '/api/pagamento/comprovante', { pagamentoId, comprovante }, { headers: this._headers });
  }

  getPDVsUnidade(unidade): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/relatorio/pdvs/' + unidade, { headers: this._headers }).pipe(
      map(res => res['data']));
  }

  checkPreVendaAtiva(idContrato): Observable<boolean> {
    this.setToken();
    return this.http.get<boolean>(this.baseApiPath + '/api/web-contrato/pre-venda/' + idContrato, { headers: this._headers });
  }

  getTarifasCartao(): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/web-tarifa-cartao', { headers: this._headers });
  }

  desativarTarifa(tarifa) {
    let params = JSON.stringify(tarifa);

    return this.http.put(this.baseApiPath + '/api/web-tarifa-cartao/desativar/', tarifa.id, { headers: this._headers }).pipe(
      map(res => res));
  }

  getTarifaCartao(tarifa): Observable<any> {
    this.setToken();

    return this.http.get(this.baseApiPath + "/api/web-tarifa-cartao/" + tarifa.id, { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  getParcelamentos(tarifaId): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/web-opcao-parcelamento/tarifa/' + tarifaId, { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  getOpcoesParcelamentoCartao(): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/web-opcao-parcelamento/todas', { headers: this._headers });
  }


  addTarifaCartao(tarifaCartao: TarifaCartao): Observable<any> {
    const params = JSON.stringify(tarifaCartao);

    return this.http.post(this.baseApiPath + "/api/web-tarifa-cartao", params, { headers: this._headers }).pipe(
      map(res => res['data']));
  }

  addParcelamento(parcelamento: Parcelamento): Observable<any> {
    const params = JSON.stringify(parcelamento);

    return this.http.post(this.baseApiPath + "/api/web-opcao-parcelamento", params, { headers: this._headers }).pipe(
      map(res => res));
  }

  getParcelamento(parcelamentoId): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/web-opcao-parcelamento/' + parcelamentoId, { headers: this._headers }).pipe(
      map(res => res['data']))
  }

  reativarOpcaoParcelamento(parcelamento) {
    let params = JSON.stringify(parcelamento.id);

    return this.http.put(this.baseApiPath + '/api/web-opcao-parcelamento/reativar', parcelamento.id, { headers: this._headers }).pipe(
      map(res => res));
  }

  desativarOpcaoParcelamento(parcelamento) {
    let params = JSON.stringify(parcelamento);

    return this.http.put(this.baseApiPath + '/api/web-opcao-parcelamento/desativar', parcelamento.id, { headers: this._headers }).pipe(
      map(res => res));
  }


  addParcelamentos(parcelamentos: Parcelamento[]): Observable<any> {
    const params = JSON.stringify(parcelamentos);

    return this.http.post(this.baseApiPath + "/api/web-opcao-parcelamento/cadastro/tarifas", params, { headers: this._headers }).pipe(
      map(res => res));
  }

  getMaquinetas(): Observable<any> {
    return this.http.get(this.baseApiPath + "/api/web-tarifa-cartao", { headers: this._headers }).pipe(
      map(res => res['data']));
  }

  getCanais(): Observable<Canal[]> {
    return this.http.get(this.baseApiPath + "/api/canal").pipe(
      map(res => res['data']));
  }

  getCanal(id: number): Observable<Canal> {
    return this.http.get(this.baseApiPath + '/api/canal/' + id).pipe(
      map(res => res['data']));
  }

  addCanal(canalForm: CadastroCanal): Observable<Canal> {
    return this.http.post(this.baseApiPath + "/api/canal", canalForm).pipe(
      map(res => res['data']));
  }

  updateCanal(canalForm: Canal): Observable<Canal> {
    const params = {
      ...canalForm
    };
    this.setToken();
    return this.http.put(this.baseApiPath + "/api/canal", JSON.stringify(params)).pipe(
      map(res => res['data']));
  }

  getAreasAtuacao(): Observable<any> {
    return this.http.get(this.baseApiPath + "/api/pessoa/area-atuacao", { headers: this._headers }).pipe(
      map(res => res['data']));
  }

  getPontosDeVenda(): Observable<any> {
    return this.http.get(this.baseApiPath + "/api/pessoa/ponto-de-venda", { headers: this._headers }).pipe(
      map(res => res['data']));
  }

  getPdvById(pdvId): Observable<any> {
    return this.http.get(this.baseApiPath + "/api/pessoa/ponto-de-venda/id/" + pdvId, { headers: this._headers }).pipe(
      map(res => res['data']));
  }

  getPessoaPontoById(pessoaPontoId): Observable<any> {
    return this.http.get(this.baseApiPath + "/api/pessoa/pessoa-ponto/id/" + pessoaPontoId, { headers: this._headers }).pipe(
      map(res => res['data']));
  }

  getAreaAtuacaoById(pdvId): Observable<any> {
    return this.http.get(this.baseApiPath + "/api/pessoa/area-atuacao/id/" + pdvId, { headers: this._headers }).pipe(
      map(res => res['data']));
  }

  criarPDV(form: any): Observable<any> {

    this.setToken();
    return this.http.post(this.baseApiPath + '/api/pessoa/ponto-de-venda', form, { headers: this._headers });
  }

  updatePontoDeVenda(pontoDeVenda) {
    return this.http.put(this.baseApiPath + '/api/pessoa/ponto-de-venda/', pontoDeVenda, { headers: this._headers }).pipe(
      map(res => res));
  }

  getSaldoGeral(): Observable<SaldoGeral[]> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/financeiro/saldo-geral', { headers: this._headers }).pipe(
      map(res => res['data']));
  }

  hasMasterFinanceiro(): Observable<any> {
    this.setToken();
    return this.http.get(this.baseApiPath + '/api/financeiro/master-financeiro', { headers: this._headers }).pipe(
      map(res => res['data']));
  }

  trySenhaDesconto(senha: string, desconto: number): Observable<SaldoGeral[]> {
    return this.http.get(this.baseApiPath + `/api/senha/${senha}/${desconto}`, { headers: this._headers }).pipe(
      map(res => res['data']));
  }
}
