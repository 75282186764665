import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from 'app/_services/rest.service';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-config-veiculos-pre-venda',
  templateUrl: './config-veiculos-pre-venda.component.html',
  styleUrls: ['./config-veiculos-pre-venda.component.less']
})
export class ConfigVeiculosPreVendaComponent implements OnInit {
  displayedColumns: string[] = ['id', 'descricao', 'cor', 'situacao', 'prazo', 'dataAtualizacao', 'acoes'];
  dataSource = [];

  loading = false;

  constructor(
    private readonly restService: RestService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getVeiculosPreVendas();
  }

  getVeiculosPreVendas() {
    this.loading = true;

    this.restService.getMotosPreVenda()
    .pipe(finalize(() => this.loading = false ))
    .subscribe(
      res => {
        this.dataSource = res;
      }
    )
  }

  goToEditVeiculoPreVenda(id: number) {
    this.router.navigate(['veiculo', id ], { relativeTo: this.route });
  }

}
