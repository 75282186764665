import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ErrorModalComponent } from 'app/theme/components/error-modal/error-modal.component';
import { SuccessModalComponent } from 'app/theme/components/success-modal/success-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private dialog: MatDialog) { }

  abrirModalSucesso(titulo: string, messagem: string) {
    this.dialog.open(SuccessModalComponent, {
      data: {
        title: titulo,
        message: messagem
      },
      width: '500px',
      height: '300px'
    });
  }

  abrirErrorModal(title: string, messages: string[]) {
    this.dialog.open(ErrorModalComponent, {
      data: {
        title: title,
        messages: messages
      },
      width: '500px',
      height: '300px'
    });
  }
}
