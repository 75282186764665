import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { OperacaoDefault } from 'app/_enums/operacaoDefaultImportacaoLancamento';
import { pessoaTipoEnum } from 'app/_enums/pessoaTipo.enum';
import { DadosFiltradosImportacaoLancamento } from 'app/_models/dadosFiltradosImportacaoLancamento';
import { Lancamento } from 'app/_models/lancamento';
import { Operacao } from 'app/_models/operacao';
import { PlanilhaImportacaoLancamento } from 'app/_models/planilhaImportacaoLancamento';
import { LancamentoService } from 'app/_services/lancamento.service';
import { ModalService } from 'app/_services/modal.service';
import { MovimentacoesService } from 'app/_services/movimentacoes.service';
import { ConfirmModalComponent } from 'app/theme/components/confirm-modal/confirm-modal.component';
import { SuccessModalComponent } from 'app/theme/components/success-modal/success-modal.component';
import { finalize } from 'rxjs/operators';
@Component({
  selector: 'app-importar-lancamentos',
  templateUrl: './importar-lancamentos.component.html',
  styleUrls: ['./importar-lancamentos.component.less']
})
export class ImportarLancamentosComponent implements OnInit {
  file: any = null;

  displayedColumns: string[] = ['dataMov', 'tipo', 'pessoa', 'historico', 'operacoes', 'valor'];
  lancamentos: PlanilhaImportacaoLancamento[] = [];
  dadosFiltrados: DadosFiltradosImportacaoLancamento[] = [];

  importandoDados = false;
  filtrandoDados = false;
  gerandoLancamentos = false;
  isError = false;
  dataAlemTrintaDias = false;
  warning = false;
  erros: string[] = [];

  diasLimite = 30;
  warningMessage = `Atenção, alguma data está além do período de ${this.diasLimite} dias...`;

  // Dados de filtros
  operacaoPdvList: Operacao[] = [];
  operacaoControleSaldoList: Operacao[] = [];
  operacaoFornecedorList: Operacao[] = [];
  operacaoIndicadorList: Operacao[] = [];

  filtroForm = this._formBuilder.group({
    operacaoPdv: ['', Validators.required],
    operacaoControleSaldo: ['', Validators.required],
    operacaoFornecedor: ['', Validators.required],
    operacaoIndicador: ['', Validators.required]
  });

  constructor(
    private lancamentoService: LancamentoService,
    private _formBuilder: FormBuilder,
    private movimentacaoService: MovimentacoesService,
    private dialog: MatDialog,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.getOperacaoByTipoPessoa(pessoaTipoEnum.PDV, OperacaoDefault.AJUSTE_SALDO_PDV, 'operacaoPdv');
    this.getOperacaoByTipoPessoa(pessoaTipoEnum.CONTROLE_DE_SALDO, OperacaoDefault.CONTROLE_DE_SALDO, 'operacaoControleSaldo');
    this.getOperacaoByTipoPessoa(pessoaTipoEnum.FORNECEDOR, OperacaoDefault.AJUSTE_SALDO_DE_FORNECEDOR, 'operacaoFornecedor');
    this.getOperacaoByTipoPessoa(pessoaTipoEnum.INDICADOR, OperacaoDefault.AJUSTE_SALDO_INDICADOR, 'operacaoIndicador');
  }

  openModal() {
    if (!this.filtroForm.valid) {
      return;
    }

    if (!this.warning) {
      this.gerarLancamentos();
      return;
    }

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '30%',
      data: {
        message: 'O Lançamento possui um ALERTA, você realmente deseja confirmar a operação?'
      },
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.gerarLancamentos();
      }
    })
  }

  gerarLancamentos() {
    this.gerandoLancamentos = true;

    let lancamentos = this.mapearLancamentos();
    this.dadosFiltrados = [];
    this.lancamentoService.postImportacaoLancamentos(lancamentos).pipe(
      finalize(() => {
        this.gerandoLancamentos = false;
        this.warning = false;
        this.dataAlemTrintaDias = false;
      }))
      .subscribe({
        next: (_) => {
          this.dadosFiltrados = [];
          this.dialog.open(SuccessModalComponent, {
            data: {
              title: 'Lançamento realizado!',
              message: 'Lançamento foi realiza com sucesso, clique em voltar para realizar outro lançamento.'
            }
          });
        }, error: (_) => {
          this.dadosFiltrados = [];
          let title ='Ocorreu um erro!';
          let messages = ['Ocorreu um erro ao gerar os lançamentos, contate o administrador.'];
          this.modalService.abrirErrorModal(title, messages);
        }
      });
  }

  mapearLancamentos() {
    let lancamentos: Lancamento[] = this.dadosFiltrados.map(dado => {
      let operacao: Operacao;

      switch (dado.tipo.tipo) {
        case pessoaTipoEnum.PDV:
          operacao = this.filtroForm.get('operacaoPdv').value as Operacao;
          break;
        case pessoaTipoEnum.CONTROLE_DE_SALDO:
          operacao = this.filtroForm.get('operacaoControleSaldo').value as Operacao;
          break;
        case pessoaTipoEnum.FORNECEDOR:
          operacao = this.filtroForm.get('operacaoFornecedor').value as Operacao;
          break;
        case pessoaTipoEnum.INDICADOR:
          operacao = this.filtroForm.get('operacaoIndicador').value as Operacao;
          break;
      }

      return {
        idPessoa: dado.idPessoa,
        dataMovimentacao: dado.dataMovimentacao,
        historico: dado.historico,
        idOperacoes: dado.operacoes.id,
        operacao: {
          id: operacao.id,
          idSubTipo: operacao.idSubtipo,
          idTipo: operacao.idTipo
        },
        valor: dado.valor
      };
    });

    return lancamentos;
  }

  handleFileInput(e: Event) {
    this.dadosFiltrados = [];
    this.isError = false;
    this.warning = false;
    this.dataAlemTrintaDias = false;
    e.stopPropagation();
    e.preventDefault();

    this.importandoDados = true;
    const inputNode: any = document.getElementById('file') as HTMLInputElement;
    this.file = inputNode.files ? inputNode.files[0] : null;

    if (!this.file) {
      this.importandoDados = false;
      return;
    }
    this.importarLancamento(this.file);

    inputNode.value = '';
  }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    (event.currentTarget as HTMLElement).classList.add('dragover');
  }

  handleDrop(event: DragEvent) {
    this.dadosFiltrados = [];
    this.isError = false;
    this.warning = false;
    this.dataAlemTrintaDias = false;
    event.preventDefault();
    event.stopPropagation();
    (event.currentTarget as HTMLElement).classList.remove('dragover');

    this.importandoDados = true;

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.file = event.dataTransfer.files[0];
      this.importarLancamento(this.file);
    } else {
      this.importandoDados = false;
      return;
    }
  }

  importarLancamento(file: File) {
    if (typeof Worker !== 'undefined') {
      const worker = new Worker('app/web-workers/import-xlsx.worker', { type: 'module' });
      worker.onmessage = ({ data }) => {
        this.importandoDados = false;
        if (data.error) {
          alert(data.error);
        } else if (data.success) {
          this.lancamentos = data.success;
          this.filtrarDadosBrutoImportacao();
        }
        worker.terminate();
      };

      worker.postMessage({ file });
    } else {
      alert('Web Workers não são suportados no seu navegador.');
      this.importandoDados = false;
    }
  }

  filtrarDadosBrutoImportacao() {
    this.importandoDados = false;
    this.filtrandoDados = true;
    this.lancamentoService.obterDadosFiltradosImportacaoLancamentos(this.lancamentos)
      .pipe(
        finalize(() => {
          this.filtrandoDados = false;
        })
      )
      .subscribe({
        next: (res: DadosFiltradosImportacaoLancamento[]) => {
          this.dadosFiltrados = res;
          this.checkRangeDataMovimentacao();
        }, error: (error) => {
          this.isError = true;
          this.erros = [...error.error.errors];
        }
      });
  }

  getOperacaoByTipoPessoa(tipoPessoa: string, operacaoDefault: string, formControlName: string) {
    this.movimentacaoService.getOperacaoByTipoPessoa(tipoPessoa).subscribe(res => {
      switch (tipoPessoa) {
        case pessoaTipoEnum.PDV:
          this.operacaoPdvList = res;
          break;
        case pessoaTipoEnum.CONTROLE_DE_SALDO:
          this.operacaoControleSaldoList = res;
          break;
        case pessoaTipoEnum.FORNECEDOR:
          this.operacaoFornecedorList = res;
          break;
        case pessoaTipoEnum.INDICADOR:
          this.operacaoIndicadorList = res;
          break;
      }
      this.setDefaultOption(res, operacaoDefault, formControlName);
    });
  }

  calcularDiffDias(date: string) {
    const hoje = new Date();
    const dataMovimentacao = new Date(date);

    const diffTime = Math.abs(hoje.getTime() - dataMovimentacao.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  checkRangeDataMovimentacao() {
    this.warning = false;
    this.dataAlemTrintaDias = false;
    const hoje = new Date();

    const dado = this.dadosFiltrados.find(dado => {
      const dataMov = new Date(dado.dataMovimentacao);

      const diffDays = this.calcularDiffDias(dado.dataMovimentacao);
      const diffMaiorTrintaDias = diffDays > this.diasLimite;

      if (diffMaiorTrintaDias && dataMov > hoje) {
        this.dataAlemTrintaDias = true;
      }

      return diffDays > this.diasLimite;
    });

    if (dado) {
      this.warning = true;
    }
  }

  isDiferencaMaiorTrintaDias(date: string) {
    const diffDays = this.calcularDiffDias(date);
    return diffDays > this.diasLimite;
  }

  setDefaultOption(operacaoList: Operacao[], descricaoOperacao: string, formControlName: string) {
    const operacao = operacaoList.find(operacao => operacao.descricao === descricaoOperacao);

    if (operacao) {
      this.filtroForm.get(formControlName).setValue(operacao);
    }
  }
}

