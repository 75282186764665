import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { pessoaTipoEnum } from 'app/_enums/pessoaTipo.enum';
import { CreateLotePagamento } from 'app/_models/createLotePagamento';
import { IPeopleOperation } from 'app/_models/movimentacoes';
import { OperacaoFinanceiraResume } from 'app/_models/operacaoFinanceiraResume';
import { PagamentosSaldos } from 'app/_models/PagamentosSaldos';
import { PessoaResume } from 'app/_models/pessoaResume';
import { Tipo } from 'app/_models/tipo';
import { TipoPessoa } from 'app/_models/tipoPessoa';
import { LotePagamentosService } from 'app/_services/lote-pagamentos.service';
import { ModalService } from 'app/_services/modal.service';
import { RestService } from 'app/_services/rest.service';
import { ConfirmModalComponent } from 'app/theme/components/confirm-modal/confirm-modal.component';
import { DateHelper } from 'app/theme/shared/helpers/date.helper';
import { forkJoin, Observable, Subject } from 'rxjs';
import { 
  debounceTime, 
  distinctUntilChanged, 
  finalize, 
  map, 
  startWith, 
  switchMap, 
  take 
} from 'rxjs/operators';

@Component({
  selector: 'app-pagamento-saldo',
  templateUrl: './pagamento-saldo.component.html',
  styleUrls: ['./pagamento-saldo.component.less']
})
export class PagamentoSaldoComponent implements OnInit {
  pagamentoForm = this.fb.group({
    dataLancamento: [DateHelper.DataHojeFormatada(), 
      [Validators.required, Validators.pattern(/^\d{4}\-\d{2}\-\d{2}$/)]
    ],
    dataVencimento: [DateHelper.DataHojeFormatada(), 
      [Validators.required, Validators.pattern(/^\d{4}\-\d{2}\-\d{2}$/)]
    ],
    operacao: [null, Validators.required],
    tipo: [null, Validators.required],
    subtipo: [null, Validators.required],
    historico: ['', Validators.required],
    operacoes: [null, Validators.required],
    pagamentoSaldoRequest: this.fb.array([])
  });
  pagando = false;
  PessoaTipo = pessoaTipoEnum;

  operacoesFinanceiras: OperacaoFinanceiraResume[] = [];
  tipos: Tipo[] = [];
  subTipos: Tipo[] = [];

  pagamentosSaldo$: Observable<PagamentosSaldos> = this.lotePagamentosService.pagamentosSaldo$;

  filteredOptions!: Observable<PessoaResume[]>;
  private searchTerms = new Subject<string>();

  operacoes: { id : number, text: string }[] = [];
  filteredOperacoes: Observable<any>;

  operacaoPessoas: IPeopleOperation[] = [];

  tiposPessoaMap = new Map();

  operacaoMesmoTipoPessoa = false;

  @ViewChild('formContainer', { static: false }) formContainer: ElementRef;


  constructor(
    private readonly fb: FormBuilder,
    private readonly lotePagamentosService: LotePagamentosService,
    private readonly restService: RestService,
    private readonly location: Location,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly modalService: ModalService
  ) { }

  ngOnInit() {
    this.filterPeople();
    this.createPagamentosSaldo();
  }

  setTipoAndSubtipoByOperacao(operacao: OperacaoFinanceiraResume) {
    let tipoFound = this.tipos.find(tipo => tipo.id === operacao.idTipo);
    let subtipoFound = this.subTipos.find(subtipo => subtipo.id === operacao.idSubTipo);

    this.pagamentoForm.get('operacao').setValue(operacao);
    this.pagamentoForm.get('tipo').setValue(tipoFound);
    this.pagamentoForm.get('subtipo').setValue(subtipoFound);
  }

  createPagamentosSaldo() {
    this.lotePagamentosService.pagamentosSaldo$
    .pipe(take(1))
    .subscribe(pagamento => {
      const tipos$ = this.restService.getAllTipos();
      const subtipos$ = this.restService.getAllSubTipos();
      const operacoes$ = this.restService.getUnidades();
      const tipoPessoa$ = this.restService.getTodosTipoPessoa();
      const operacoesFinanceiras$ = this.restService.getOperacaoByTipoPessoaId(pagamento.tipoPessoa.tipoPessoaId);

      forkJoin([tipos$, subtipos$, operacoesFinanceiras$, operacoes$, tipoPessoa$])
      .subscribe(([
        tiposResponse, 
        subtiposResponse, 
        operacaoResponse, 
        operacoesResponse,
        tipoPessoaResponse]) => {
        this.operacoesFinanceiras = operacaoResponse;
        this.tipos = tiposResponse;
        this.subTipos = subtiposResponse;
        this.tipoPessoaArrayToMap(tipoPessoaResponse);
        this.pagamentoForm.get('operacao').setValue(operacaoResponse);
        this.pagamentoForm.get('tipo').setValue(tiposResponse);
        this.pagamentoForm.get('subtipo').setValue(subtiposResponse);

        this.operacoes = operacoesResponse.data.map(op => { return  { id: op.id, text: op.text }});
        
        this.setAutoCompleteOperacoes();
        if (this.operacoesFinanceiras.length == 1) this.setTipoAndSubtipoByOperacao(this.operacoesFinanceiras[0]);
      });
      
      pagamento.saldosAPagar.forEach(saldo => {
          const pagamentosSaldo = this.fb.group({
            idPessoa: [saldo.idPessoa],
            pessoa: [{value: saldo.nomePessoa, disabled: true}],
            tipoPessoa: [pagamento.tipoPessoa],
            valorSaldo: [saldo.valorSaldo],
            valorALancar: [saldo.valorSaldo, Validators.required],
            operacaoPessoas: this.fb.array([])
          });
          this.pagamentoSaldoRequest.push(pagamentosSaldo);
      });
    });
  }

  tipoPessoaArrayToMap(tiposPessoa: TipoPessoa[]) {
    const map = tiposPessoa.reduce((acc, item) => {
      acc.set(item.tipo, item.descricao);
      return acc;
    }, new Map());
    
    map.set(this.PessoaTipo.CONTA_QUALQUER, "CONTA/CAIXA");
    this.tiposPessoaMap = map;
  }

  setAutoCompleteOperacoes() {
    this.filteredOperacoes = this.pagamentoForm.get('operacoes').valueChanges
        .pipe(
          startWith(''),
          map(term => this.filterOperacoes(term)),
        );
  }

  filterOperacoes(value: string) {
    if (typeof value == 'string') {
        const filterValue = value.toLowerCase();
        return this.operacoes.filter(option => 
                (typeof option.text === 'string' && option.text.toLowerCase().indexOf(filterValue) !== -1));
    }
  }

  filterPeople() {
    this.filteredOptions = this.searchTerms.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        switchMap(
            (term: string) =>
                this.restService
                    .buscarPessoaSelect('texto=' + term.split(' ')[1] + `&all=${term.split(' ')[0]}`)
                    .map(item => item.results)
        )
    );
  }

  searchContaCaixa(term: string, tipoPessoa: string): void {
    if (term.length > 2) {
        this.searchTerms.next(`${tipoPessoa} ${term}`);
    }
  }

  displayFn(term: { id: number, text: string }): string {
    return term && term.text ? term.text : '';
  }

  onChangeOperacao(operacao: OperacaoFinanceiraResume) {
    this.setTipoAndSubtipoByOperacao(operacao);

    this.restService.getOperacaoPessoas(operacao.idOperacao)
    .subscribe(res => {
      this.operacaoPessoas = res;
      this.operacaoMesmoTipoPessoa = this.verificarRepeticaoTipoPessoaLancamento();

      this.pagamentoSaldoRequest.controls.forEach(pagamento => {
        const operacaoPessoasArray = pagamento.get('operacaoPessoas') as FormArray;
        operacaoPessoasArray.clear();

        // Caso o tipo for repetido, ele seta apenas o primeiro
        let primeiroTipo = false;
        this.operacaoPessoas.forEach(operacao => {
          let operacaoFinanceira = this.fb.group({});

          if (operacao.tipoPessoa === pagamento.get('tipoPessoa').value.tipo || 
              (operacao.tipoPessoa === pessoaTipoEnum.CONTA_QUALQUER && 
                pagamento.get('tipoPessoa').value.tipo === pessoaTipoEnum.CAIXA || 
                pagamento.get('tipoPessoa').value.tipo === pessoaTipoEnum.CONTA_CONCILIACAO ||
                pagamento.get('tipoPessoa').value.tipo === pessoaTipoEnum.CONTA_CORRENTE_BANCO
              )
              && !primeiroTipo
            ) {
              primeiroTipo = true;

              operacaoFinanceira = this.fb.group({
                idOperacaoPessoa: operacao.id,
                acao: [operacao.acao],
                pessoa: [{ 
                  value: {
                    id: pagamento.get('idPessoa').value,
                    text: pagamento.get('pessoa').value
                  }, 
                  disabled: true}],
                pessoaId: [pagamento.get('idPessoa').value],
                tipoPessoa: [operacao.tipoPessoa]
              });
              operacaoPessoasArray.push(operacaoFinanceira);
          } else {
            if (operacao.idPessoaPadrao != 0) {
              this.restService.getPessoa(operacao.idPessoaPadrao)
              .subscribe(res => {

                operacaoFinanceira = this.fb.group({
                  idOperacaoPessoa: operacao.id,
                  acao: [operacao.acao],
                  pessoa: [ 
                    {
                      id: res.id,
                      text: res.nome
                    }, [Validators.required]],
                  tipoPessoa: [operacao.tipoPessoa]
                });
                operacaoPessoasArray.push(operacaoFinanceira);

              })
            } else {
              operacaoFinanceira = this.fb.group({
                idOperacaoPessoa: operacao.id,
                acao: [operacao.acao],
                pessoa: [null, [Validators.required]],
                tipoPessoa: [operacao.tipoPessoa]
              });
              operacaoPessoasArray.push(operacaoFinanceira);
            }
          }
        });
      });
    });
  }

  verificarRepeticaoTipoPessoaLancamento() {
    let tipoPessoaMap = new Map();
    let operacaoMesmaTipoPessoa = false;

    this.operacaoPessoas.forEach(operacao => {
      operacaoMesmaTipoPessoa = tipoPessoaMap.has(operacao.tipoPessoa);
     
      tipoPessoaMap.set(operacao.tipoPessoa, 1);
    });

    return operacaoMesmaTipoPessoa;
  }

  pagar() {
    
    if (this.pagamentoForm.invalid) {
      this.pagamentoForm.markAllAsTouched();
      this.emitirErrosPagamentos();

      const firstInvalidControl: HTMLElement = this.formContainer.nativeElement.querySelector(
        '.ng-invalid'
      );

      if (firstInvalidControl) {
        firstInvalidControl.scrollIntoView({ });
        firstInvalidControl.focus();
      }
      return;
    }

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '25%',
      data: { message: 'Você tem certeza que deseja realizar os pagamentos?' }
    });

    dialogRef.afterClosed().subscribe(ok => {
      if (ok) {
        this.pagando = true;
        const requestBody = this.createRequestBodyLotePagamento();

        this.lotePagamentosService.saveLote(requestBody)
        .pipe(finalize(() => {
          this.pagando = false;
        }))
        .subscribe(_ => {
          this.snackBar.open('Pagamento de lote de saldo efetuado com sucesso!', '', {
            duration: 4000,
            panelClass: ['success-snackbar']
          });
          this.lotePagamentosService.limparPagamentosSelecionados();
          this.back();
        }, error => {
          let title = 'Ocorreu um erro!';
          let messages = error.error.errors;
          this.modalService.abrirErrorModal(title, messages);
          console.error(error);
        });
      }
    })
  }

  private createRequestBodyLotePagamento() {
    const body: CreateLotePagamento = {
      dataLancamento: this.pagamentoForm.get('dataLancamento').value,
      dataVencimento: this.pagamentoForm.get('dataVencimento').value,
      operacaoId: this.pagamentoForm.get('operacao').value.idOperacao,
      tipoId: this.pagamentoForm.get('tipo').value.id,
      subtipoId: this.pagamentoForm.get('subtipo').value.id,
      historico: this.pagamentoForm.get('historico').value,
      operacoesId: this.pagamentoForm.get('operacoes').value.id,
      pagamentoSaldoRequest: (this.pagamentoSaldoRequest.controls).map((pagamento, idx) => {
        return {
          valorALancar: pagamento.get('valorALancar').value,
          principalPessoaId: pagamento.get('idPessoa').value,
          lancamentos: this.getOperacaoPessoas(idx).controls.map((operacaoFinanceira) => {
            return {
              idOperacaoPessoa: operacaoFinanceira.get('idOperacaoPessoa').value,
              pessoaId: operacaoFinanceira.get('pessoa').value.id,
              acao: operacaoFinanceira.get('acao').value,
            }
          })
        }
      })
    }
    
    return body;
  }

  private emitirErrosPagamentos() {
    this.pagamentoSaldoRequest.controls.forEach((control, idx) => {
      control.markAllAsTouched();
      control.updateValueAndValidity();

      this.getOperacaoPessoas(idx).controls.forEach((operacaoControl) => {
        operacaoControl.markAllAsTouched();
        operacaoControl.updateValueAndValidity();
      })
    });
  }

  deletePagamento(idx: number) {
    this.pagamentoSaldoRequest.removeAt(idx);

    if (this.pagamentoSaldoRequest.length <= 0) 
      this.back();
    this.lotePagamentosService.deleteSaldoAPagar(idx);
  }

  trocarPessoaPagamento(pagamentoId: number) {
    let operacaoPessoas = this.getOperacaoPessoas(pagamentoId);

    if (operacaoPessoas.length < 2) return;

    const primeiroItem = operacaoPessoas.at(0);
    const segundoItem = operacaoPessoas.at(1);
  
    const primeiroAcao = primeiroItem.get('acao').value;
    const segundoAcao = segundoItem.get('acao').value;
  
    primeiroItem.get('acao').setValue(segundoAcao);
    segundoItem.get('acao').setValue(primeiroAcao);
  }

  back() {
    this.location.back();
  }

  get pagamentoSaldoRequest() {
    return this.pagamentoForm.controls["pagamentoSaldoRequest"] as FormArray;
  }

  getOperacaoPessoas(pagamentoIndex: number): FormArray {
    return this.pagamentoSaldoRequest.at(pagamentoIndex).get('operacaoPessoas') as FormArray;
  }
}
