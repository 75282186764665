import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateLotePagamento } from 'app/_models/createLotePagamento';
import { InfoLote } from 'app/_models/lote-info';
import { LotePagamentos } from 'app/_models/lote-pagamento';
import { OperacaoFinanceiraResume } from 'app/_models/operacaoFinanceiraResume';
import { PagamentosSaldos } from 'app/_models/PagamentosSaldos';
import { Pageable } from 'app/_models/Pageable';
import { SaldosAPagar } from 'app/_models/saldosAPagar';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs-compat';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LotePagamentosService {
  baseApiPath: string = environment.url_api;

  private pagamentosSaldoObservable = new BehaviorSubject<PagamentosSaldos>(null);
  pagamentosSaldo$ = this.pagamentosSaldoObservable.asObservable();

  private saldoAPagarSelecionados = new BehaviorSubject<SaldosAPagar[]>([]);
  saldoAPagarSelecionados$ = this.saldoAPagarSelecionados.asObservable();

  constructor(
    private readonly http: HttpClient
  ) {
    let items = localStorage.getItem("pagamentos");
    if (items) {
      let pagamentosSaldo = JSON.parse(items) as PagamentosSaldos;
      this.setPagamentos(pagamentosSaldo);
    }
  }

  saveLote(body: CreateLotePagamento) {
    return this.http.post<any>(`${this.baseApiPath}/api/lote/gerar`, body);
  }

  getOperacoesFinanceirasByTipo(tipo: string) {
    return this.http.get<OperacaoFinanceiraResume[]>(`${this.baseApiPath}/api/pagamento/saldo/${tipo}`).pipe(
      map(response => response['data'])
    );
  }

  getSaldosAPagar(
    data: string, 
    tipo: string,
    nome: string,
    pageNo: number, 
    pageSize: number
  ) {
    if (nome === null ||nome === undefined) nome = '';
    
    return this.http.get<Pageable<SaldosAPagar>>(`${this.baseApiPath}/api/pagamento/saldo/page`, {
      params: {
        data: data,
        tipo: tipo,
        pageNo: pageNo.toString(),
        pageSize: pageSize.toString(),
        nome: nome
      }
    }).pipe(
      map(response => response['data'])
    );
  }

  getAllLotePagamentos(page: number, size: number): Observable<Pageable<LotePagamentos>> {
    return this.http.get(`${this.baseApiPath}/api/lote/page`, {
      params: {
        pageNo: page.toString(),
        pageSize: size.toString()
      }
    }).pipe(map(res => res['data']));
  }

  getLotePagamentoById(loteId: number) : Observable<InfoLote> {
      return this.http.get(`${this.baseApiPath}/api/lote/${loteId}`)
                    .pipe(map(res => res['data']));
  }

  addSaldos(saldos: SaldosAPagar[]) {
    let selecionados = new Set([...this.saldoAPagarSelecionados.getValue(), ...saldos]);
    this.saldoAPagarSelecionados.next([...selecionados]);
  }

  removeSaldo(saldo: SaldosAPagar) {
    let selecionados = new Set(this.saldoAPagarSelecionados.getValue());

    selecionados.delete(saldo);
    this.saldoAPagarSelecionados.next([...selecionados]);
  } 

  clearSaldoSelecionados() {
    this.saldoAPagarSelecionados.next([]);
  }
  
  hasSaldoSelecionado() {
   return this.saldoAPagarSelecionados.getValue().length > 0;
  }

  limparPagamentosSelecionados() {
    localStorage.setItem("pagamentos", JSON.stringify([]));
    this.pagamentosSaldoObservable.next(null);
    this.saldoAPagarSelecionados.next([]);
  }

  addPagamentoSaldo(dataSaldo: string, tipoPessoa: string, tipoPessoaId: number) {
    const pagamento: PagamentosSaldos = {
      dataSaldo: dataSaldo,
      tipoPessoa: {
        tipoPessoaId: tipoPessoaId,
        tipo: tipoPessoa
      },
      saldosAPagar: this.saldoAPagarSelecionados.getValue()
    };

    this.setPagamentos(pagamento);
  }

  setPagamentos(pagamento: PagamentosSaldos) {
    localStorage.setItem("pagamentos", JSON.stringify(pagamento));
    this.pagamentosSaldoObservable.next(pagamento);
  }

  deleteSaldoAPagar(idx: number) {
    let items = localStorage.getItem("pagamentos");
    if (items) {
      let pagamentosSaldo = JSON.parse(items) as PagamentosSaldos;
      if (idx < pagamentosSaldo.saldosAPagar.length)
        pagamentosSaldo.saldosAPagar.splice(idx, 1);
      this.setPagamentos(pagamentosSaldo);
    }
  }
}
