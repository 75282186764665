import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { InfoLote } from 'app/_models/lote-info';
import { MovimentacaoLote } from 'app/_models/movimentacaoLote';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { LotePagamentosService } from 'app/_services/lote-pagamentos.service';

@Component({
  selector: 'app-lote',
  templateUrl: './lote.component.html',
  styleUrls: ['./lote.component.less']
})
export class LoteComponent implements OnInit {
  displayedColumns: string[] = ['data', 'operacao', 'historico', 'valor'];
  dataSource: MatTableDataSource<MovimentacaoLote> = new MatTableDataSource();

  pathId = null;

  lote: InfoLote = {
    movimentacoes: [],
    loteId: 0,
    descricao: '',
    autor: '',
    geradoEm: ''
  };

  constructor(
    private readonly location: Location,
    private _lotePagamentoService: LotePagamentosService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.get('id') != null) {
        const id =  params.get('id');
        this.pathId = id;
        this.getMovimentacoesPorIdLote(parseInt(id))
      }
    });
  };

  getMovimentacoesPorIdLote(id: number):void {
    this._lotePagamentoService.getLotePagamentoById(id)
    .subscribe(res => {
      this.lote = res;
    })
  }
  
  back() {
    this.location.back();
  }

}
