import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportationButtonComponent } from './exportation-button/exportation-button.component';
import { WarningBarComponent } from './warning-bar/warning-bar.component';
import { MatButtonModule, MatDialogModule, MatProgressSpinnerModule } from '@angular/material';
import { SharedWhatsappComponent } from './shared-whatsapp/shared-whatsapp.component';
import { FilterCardComponent } from './filter-card/filter-card.component';
import { CardComponent } from './card/card.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';


@NgModule({
  declarations: [
    ExportationButtonComponent,
    WarningBarComponent,
    SharedWhatsappComponent,
    FilterCardComponent,
    CardComponent,
    ErrorModalComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule
  ],
  exports: [
    ExportationButtonComponent,
    WarningBarComponent,
    SharedWhatsappComponent,
    FilterCardComponent,
    CardComponent,
  ],
  entryComponents: [
    ErrorModalComponent
  ]
})
export class SharedModule { }
