import * as format from "date-fns/format";

export class DateHelper {
 
    /**
     * Obtém a data atual no formato para componente Angular Material
     * formato: yyyy/MM/dd
     * 
     * @returns {'yyyy/MM/dd'} Data formatada para Angular Material
     */
    static DataHojeFormatada(): string {
        return format(new Date(), 'YYYY-MM-DD');
    }
}