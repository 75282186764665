import { Component, Input, OnInit } from '@angular/core';
import { transform } from 'typescript';

@Component({
  selector: 'app-filter-card',
  template: `
    <div id="search-box">
      <div *ngIf="tituloCard">
        <h6>{{tituloCard}}</h6>
        <hr>
      </div>
      <ng-content>
      </ng-content>
    </div>
  `,
  styleUrls: ['./filter-card.component.less']
})
export class FilterCardComponent implements OnInit {
  tituloCard: string = '';

  constructor() { }

  ngOnInit() {
  }

  @Input() set titulo(titulo: string) {
    this.tituloCard = titulo.trim();
  }
}
